/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
import React from 'react';
import InputMask from 'react-input-mask';

import {
  Form,
  Input,
  Select,
} from 'antd';

import { UserTypes } from 'src/constants/enums';
import useTypedSelector from 'src/hooks/useTypedSelector';

import cities from '../../../assets/data/cities';

import s from '../Settings.module.css';
import '../Setting.css';

const CustomerForm = () => {
  const userData = useTypedSelector((state) => state.userProfile);

  const canEdit = userData.type === UserTypes.Customer;

  return (
    <div className={s.changeUserInfoGroupItems}>
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>
          Название филиала
        </span>
        <Form.Item
          className={s.inputDescription}
          name="mainBranchName"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите название филиала',
            },
            {
              pattern: /^[А-Яа-я\s\d-_]*$/,
              message: 'Пожалуйста, введите название филиала корректно',
            },
          ]}
        >
          <Input
            id="mainBranchName"
            type="text"
            placeholder="Название филиала"
            maxLength={20}
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Имя</span>
        <Form.Item
          className={s.inputDescription}
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите имя',
            },
            {
              pattern: new RegExp('^[А-Яа-я]+$'),
              message: 'Пожалуйста, введите имя кириллицей',
            },
          ]}
        >
          <Input
            id="name"
            type="text"
            placeholder="Имя"
            maxLength={20}
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Телефон</span>
        <Form.Item
          className={s.inputDescription}
          name="phone"
          rules={[{
            required: true,
            message: 'Пожалуйста, введите номер телефона',
          }]}
        >
          <InputMask
            placeholder="Телефон"
            className="ant-input"
            id="phone"
            mask="+7(999) 999-9999"
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
      {canEdit && (
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription}>Организация</span>
          <Form.Item
            className={s.inputDescription}
            name="organization"
            rules={[{
              required: true,
              message: 'Пожалуйста, введите наименование организации',
            }]}
          >
            <Input
              maxLength={200}
              id="organization"
              type="text"
              placeholder="Организация"
              disabled={!canEdit}
            />
          </Form.Item>
        </div>
      )}
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Сайт компании</span>
        <Form.Item
          className={s.formItem}
          name="website"
          rules={[
            {
              message: 'Пожалуйста, введите ссылку на сайт корректно',
              pattern: new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\=]*)?/gi),
            },
          ]}
        >
          <Input
            maxLength={200}
            id="website"
            type="text"
            placeholder="Сайт компании"
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Регион доставки</span>
        <Form.Item
          className={s.formItem}
          name={['region', 'name']}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите регион',
            },
          ]}
        >
          <Select
            options={cities.map((city) => ({
              value: city,
              label: city,
            }))}
            disabled={!canEdit}
          />
        </Form.Item>
      </div>

      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Адрес доставки</span>
        <Form.Item
          className={s.inputDescription}
          name="address"
          rules={[{
            required: true,
            message: 'Пожалуйста, введите адрес доставки',
          }]}
        >
          <Input.TextArea
            maxLength={255}
            placeholder="Адрес доставки"
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default CustomerForm;
