import * as popUpActionCreators from './popUp';
import * as authActionCreators from '../auth/actions';
import * as userProfileActionCreators from '../userProfile/actions';
import * as responseResult from './responseResult';

export default {
  ...popUpActionCreators,
  ...authActionCreators,
  ...userProfileActionCreators,
  ...responseResult,
};
