/* eslint-disable import/no-cycle */
import { Dispatch } from 'react';
import jwt_decode from 'jwt-decode';

import { UserTypes } from 'src/constants/enums';
import { AxiosResponse } from 'axios';
import {
  SetUserData,
  IUserData,
  ActionsTypes,
  ISetUserType,
} from '../../types/userProfile';

import { usersApi } from '../../api/api';

import {
  logInAC,
  setIsEmailVerified,
  setIsFetching,
  setIsLoading,
} from '../auth/actions';

import { setCurrentModal } from '../action-creators/popUp';
import { ModalsTypes } from '../../types/popUp';

export const setUserDataAC = (data: IUserData): SetUserData => ({
  type: ActionsTypes.SET_USER_DATA,
  payload: data,
});

export const setUserType = (data: { type: UserTypes }): ISetUserType => ({
  type: ActionsTypes.SET_USER_TYPE,
  payload: data,
});

export const getUserData = (token?: string) => (
  (dispatch: Dispatch<any>) => {
    dispatch(setIsFetching(true));
    usersApi.getUserData()
      .then((res: AxiosResponse<IUserData>) => {
        if (res.status === 200) {
          dispatch(logInAC({ isAuth: true }));
          dispatch(setIsEmailVerified(res.data.isActive));
          if (!res.data.isActive) {
            dispatch(setCurrentModal(ModalsTypes.confirmEmail));
          }

          const newToken = token || localStorage.getItem('token');

          if (!newToken) {
            return;
          }

          const { type }: { type: UserTypes } = jwt_decode(newToken);

          dispatch(setUserType({ type }));

          switch (type) {
            case UserTypes.UndefinedBranch: {
              dispatch(setUserDataAC(res.data));
              dispatch(setCurrentModal(ModalsTypes.chooseBranch));

              break;
            }

            case UserTypes.Branch: {
              const branchId = localStorage.getItem('branchId');

              const user = res.data.usersBranch.find(({ id }) => (
                id === Number(branchId)
              ));

              if (user) {
                dispatch(setUserDataAC({
                  ...res.data,
                  ...user,
                  mainBranchName: user.branchName,
                  name: user.userName,
                }));
              }

              break;
            }

            case UserTypes.UndefinedEmployee: {
              dispatch(setUserDataAC(res.data));
              dispatch(setCurrentModal(ModalsTypes.chooseEmployee));

              break;
            }

            case UserTypes.Employee: {
              const employeeId = localStorage.getItem('employeeId');

              const employee = res.data.usersEmployee.find(({ id }) => (
                id === Number(employeeId)
              ));

              if (employee) {
                dispatch(setUserDataAC({
                  ...res.data,
                  ...employee,
                  providerTypes: employee.supplierTypes,
                }));
              }

              break;
            }

            default: {
              dispatch(setUserDataAC(res.data));

              break;
            }
          }
        }
      })
      .finally(() => {
        dispatch(setIsFetching(false));
        dispatch(setIsLoading(false));
      });
  }
);
