import React from 'react';
import HeaderSlider from 'src/components/layout-components/Header/HeaderSlider';
import MarketHeaderForm from '../MarketHeaderForm';
import s from './index.module.css';

function MarketHeaderMobile() {
  return (
    <div>
      <HeaderSlider />
      <div className={s.mobileSearchForm}>
        <MarketHeaderForm />
      </div>
    </div>
  );
}
export default MarketHeaderMobile;
