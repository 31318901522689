import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { usersApi } from 'src/api/api';
import { IMarketGood } from 'src/constants/enums';
import { IProduct } from '../CustomerProposal/types';

const checkHasGoodInOrder = (name: string) => {
  const orderGoods = JSON.parse(localStorage.getItem('DENTMP_SHOWCASE_ORDER') || '[]') as IProduct[];

  return orderGoods.some((orderGood) => orderGood.name === name);
};

const useMarketGood = () => {
  const { id } = useParams<{ id: string }>();

  const [good, setGood] = useState<IMarketGood | null>(null);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [hasGoodInOrder, setHasGoodInOrder] = useState(false);

  const [orderGood, setOrderGood] = useState({
    amount: 1,
    type: 'штука',
  });

  useEffect(() => {
    setStatus('loading');
    setError(null);

    usersApi.getMarketGood({
      id: Number(id),
    })
      .then((e) => {
        setGood(e.data);
        setHasGoodInOrder(checkHasGoodInOrder(e.data.name));
      })
      .catch((e) => {
        console.warn(e);

        setError(e);
      })
      .finally(() => {
        setStatus('loaded');
      });
  }, [id]);

  const onClick = () => {
    const orderGoods = JSON.parse(localStorage.getItem('DENTMP_SHOWCASE_ORDER') || '[]') as IProduct[];

    const newGoods = hasGoodInOrder
      // eslint-disable-next-line @typescript-eslint/no-shadow
      ? orderGoods.map((prevOrderGood) => {
        if (prevOrderGood.name !== good?.name) {
          return prevOrderGood;
        }

        return {
          ...prevOrderGood,
          amount: orderGood.amount,
          type: orderGood.type,
        };
      })
      : orderGoods.concat(
        {
          id: Number(id),
          code: good?.code,
          name: good?.name,
          amount: String(orderGood.amount),
          type: orderGood.type,
        } as IProduct,
      );

    localStorage.setItem('DENTMP_SHOWCASE_ORDER', JSON.stringify(newGoods));

    setHasGoodInOrder(true);
  };

  const onChange = ({ name, value }: { name: string; value: any }) => {
    setOrderGood((prevOrderGood) => ({
      ...prevOrderGood,
      [name]: value,
    }));
  };

  return {
    good,
    status,
    error,
    orderGood,
    hasGoodInOrder,
    onChange,
    onClick,
  };
};

export default useMarketGood;
