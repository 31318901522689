import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { deleteEmployee } from 'src/store/employees/actions';

import { LoadingButton } from 'src/components/shared-components/Loading';

import s from './styles.module.css';

const ProviderBranchesTable = () => {
  const { usersEmployee } = useTypedSelector((store) => store.userProfile);
  const { deletingEmployeeId } = useTypedSelector((store) => store.employees);

  const dispatch = useDispatch();

  if (!usersEmployee.length) {
    return (
      <div className={s.empty}>
        <p className={s.emptyText}>
          Вы не добавили ни одного сотрудника
        </p>
      </div>
    );
  }

  const handleClick = (id: number) => {
    const answer = window.confirm('Вы уверены, что хотите удалить этого сотрудника?');

    if (answer) {
      dispatch(deleteEmployee({
        id,
      }));
    }
  };

  return (
    <table className={s.table}>
      <thead>
        <tr className={cx(s.tableRow, s.tableRowHead)}>
          <th className={cx(s.tableCell, s.tableCellHead)}>
            <span className={cx(s.tableCellText, s.tableCellHeadText)}>
              Имя сотрудника
            </span>
          </th>
          <th className={s.tableCell}>
            <span className={cx(s.tableCellText, s.tableCellHeadText)}>
              Действия
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {usersEmployee.map(({ id, name }) => {
          const isDeleting = deletingEmployeeId === id;

          return (
            <tr
              key={id}
              className={s.tableRow}
            >
              <td className={s.tableCell}>
                <span className={s.tableCellText}>
                  {name}
                </span>
              </td>
              <td className={s.tableCell}>
                <NavLink
                  className={cx(s.button, s.buttonEdit)}
                  to={`/employees/${id}/edit`}
                >
                  Правка
                </NavLink>
                <button
                  className={cx(s.button, s.buttonDelete)}
                  type="button"
                  onClick={() => handleClick(id)}
                  disabled={isDeleting}
                >
                  <span className={cx(s.buttonContent, isDeleting && s.buttonContentHidden)}>
                    Удалить
                  </span>
                  {isDeleting && (
                    <span className={s.buttonLoader}>
                      <LoadingButton />
                    </span>
                  )}
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ProviderBranchesTable;
