import React from 'react';

import {
  Pagination,
  Table,
} from 'antd';

import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import { LoadingButton } from 'src/components/shared-components/Loading';

import {
  IMarketGood,
  IMarketGoodAuthor,
} from 'src/constants/enums';

import useGoodsTable from './useGoodsTable';

import s from './index.module.css';

const GoodsTable = () => {
  const {
    goods,
    onPageChange,
    onPageSizeChange,
    page,
    limit,
    acceptingGoodId,
    onAccept,
    decliningGoodId,
    onDecline,
  } = useGoodsTable();

  if (!goods) {
    return (
      <div className={s.pageContentLoader}>
        <LoadingOutlined style={{ fontSize: 48 }} />
      </div>
    );
  }

  const columns = [
    {
      title: 'Поставщик',
      key: 'name',
      dataIndex: 'author',
      sorter: (a: IMarketGood, b: IMarketGood) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      render: (author: IMarketGoodAuthor) => (
        <span title={author.organization} className={s.tableCellName}>
          {author.organization}
        </span>
      ),
      width: 220,
    },
    {
      title: 'Наименование',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: IMarketGood, b: IMarketGood) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      render: (text: string) => (
        <span title={text} className={s.tableCellName}>
          {text}
        </span>
      ),
      width: 220,
    },
    {
      title: 'Артикул',
      key: 'code',
      dataIndex: 'code',
      sorter: (a: IMarketGood, b: IMarketGood) => a.code.localeCompare(b.code),
      showSorterTooltip: false,
      render: (text: string) => (
        <span className={s.tableCellText}>
          {text}
        </span>
      ),
      width: 180,
    },
    {
      title: 'Действия',
      key: 'action',
      dataIndex: 'id',
      render: (id: number) => {
        const isAccepting = acceptingGoodId === id;
        const isDeclining = decliningGoodId === id;

        return (
          <>
            <NavLink
              className={cx(s.button, s.buttonEdit)}
              to={{
                pathname: `/my-market/${id}/edit`,
                state: {
                  from: window.location.href,
                },
                search: window.location.search,
              }}
            >
              Посмотреть
            </NavLink>
            <button
              className={cx(s.button, s.buttonDelete)}
              type="button"
              onClick={() => onAccept(id)}
              disabled={isAccepting}
            >
              <span className={cx(s.buttonContent, isAccepting && s.buttonContentHidden)}>
                Принять
              </span>
              {isAccepting && (
                <span className={s.buttonLoader}>
                  <LoadingButton />
                </span>
              )}
            </button>
            <button
              className={cx(s.button, s.buttonDelete)}
              type="button"
              onClick={() => onDecline(id)}
              disabled={isDeclining}
            >
              <span className={cx(s.buttonContent, isDeclining && s.buttonContentHidden)}>
                Отклонить
              </span>
              {isDeclining && (
                <span className={s.buttonLoader}>
                  <LoadingButton />
                </span>
              )}
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {!goods.items.length && (
        <div className={s.empty}>
          <p className={s.emptyText}>
            Нет товаров на модерации
          </p>
        </div>
      )}
      {goods.items.length > 0 && (
        <>
          <div className={s.tableContainer}>
            <Table
              className={s.table}
              dataSource={goods.items}
              columns={columns}
              pagination={false}
              tableLayout="fixed"
            />
          </div>
          <Pagination
            current={page}
            pageSize={limit}
            showSizeChanger
            pageSizeOptions={['10', '20', '50']}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={goods.meta.totalItems}
          />
        </>
      )}
    </>
  );
};

export default GoodsTable;
