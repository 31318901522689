import {
  Button, Form, Input, Modal,
} from 'antd';

import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import common from 'src/components/shared-components/Modals/Modals.module.css';
import { LoadingButton } from 'src/components/shared-components/Loading';

import { IBranchCredentials, IEmployeeCredentials } from 'src/constants/enums';

import s from './index.module.css';

const modalStyles = {
  width: '80%',
  margin: '0 auto',
};

interface AddCredentialsProps {
  title: string;
  isSaving: boolean;
  onSubmit: (values: IBranchCredentials | IEmployeeCredentials) => void;
  error?: string | null;
}

const AddCredentials: React.FC<AddCredentialsProps> = (props) => {
  const {
    title,
    isSaving,
    onSubmit,
    error,
  } = props;

  const [isOpened, setIsOpened] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!isOpened) {
      form.resetFields();
    }
  }, [isOpened]);

  useEffect(() => {
    if (error) {
      form.setFields([
        {
          name: 'login',
          errors: [error],
        },
      ]);
    }
  }, [error]);

  return (
    <>
      <div className={s.submit_button_container}>
        <button
          className={s.transparent_button}
          type="button"
          onClick={() => setIsOpened(true)}
        >
          Задать логин и пароль
        </button>
      </div>
      <Modal
        bodyStyle={modalStyles}
        centered
        visible={isOpened}
        onCancel={() => setIsOpened(false)}
        footer={null}
        title={title}
      >
        <Form
          onFinish={onSubmit}
          form={form}
        >
          <Form.Item
            className={common.formItem}
            name="login"
            label="Введите логин"
            rules={[{
              required: true,
              message: 'Пожалуйста, введите логин',
            }, {
              pattern: /^[a-zA-Z\d][a-zA-Z\d_.-]{4,30}$/,
              message: 'Пожалуйста, введите логин корректно',
            }]}
          >
            <Input
              placeholder="Логин"
            />
          </Form.Item>
          <Form.Item
            className={s.formItem}
            label="Введите пароль"
            name="password"
            rules={[{
              required: true,
              message: 'Пожалуйста, введите пароль',
            }, {
              pattern: /^\w*$/,
              message: 'Пожалуйста, введите пароль корректно',
            }, {
              min: 6,
              message: 'Пароль должен быть больше 6 символов',
            }, {
              max: 50,
              message: 'Пароль не должен быть больше 50 символов',
            }]}
          >
            <Input.Password
              placeholder="Пароль"
            />
          </Form.Item>
          <div className={cx(common.footer)}>
            <Button
              type="primary"
              className={common.button}
              htmlType="submit"
              disabled={isSaving}
            >
              <span className={cx(s.buttonContent, isSaving && s.buttonContentHidden)}>
                Сохранить
              </span>
              {isSaving && (
                <span className={s.buttonLoader}>
                  <LoadingButton />
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddCredentials;
