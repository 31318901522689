import React, { useEffect, useState } from 'react';

import {
  Button,
  Form,
  Modal,
  Select,
} from 'antd';

import useTypedSelector from 'src/hooks/useTypedSelector';
import useActions from 'src/hooks/useActions';

import { ModalsTypes } from 'src/types/popUp';

import common from '../Modals.module.css';

const ChooseEmployee = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const pupups = useTypedSelector((state) => state.popUps);
  const { usersEmployee } = useTypedSelector((state) => state.userProfile);
  const { isFetching } = useTypedSelector((state) => state.auth);

  const { authUser, setCurrentModal } = useActions();

  const [form] = Form.useForm();

  useEffect(() => {
    setVisible(pupups.currentModal === ModalsTypes.chooseEmployee);
  }, [pupups]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const handleSubmit = (values: { employeeId: number }) => {
    authUser({
      employeeId: values.employeeId,
      callback: () => setCurrentModal(ModalsTypes.null),
    });
  };

  return (
    <Modal
      centered
      title="Выберите сотрудника"
      visible={visible}
      footer={null}
      closable={false}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="employeeId"
          className={common.formItem}
          rules={[{
            required: true,
            message: 'Выберите сотрудника',
          }]}
        >
          <Select placeholder="Выберите сотрудника">
            {usersEmployee.map(({ id, name }) => (
              <Select.Option
                key={id}
                value={id}
              >
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className={common.footer}>
          <Button
            type="primary"
            htmlType="submit"
            className={common.button}
            loading={isFetching}
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChooseEmployee;
