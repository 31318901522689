import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'antd';
import { useParams, Link } from 'react-router-dom';
import ContainerOutlined from '@ant-design/icons/ContainerOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import cx from 'classnames';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { saveEmployee } from 'src/store/employee/actions';

import routes from 'src/routes';

import { LoadingButton } from 'src/components/shared-components/Loading';

import { IEmployee } from 'src/constants/enums';

import AddProviderBranchForm from '../../components/AddProviderBranchForm';

import s from './styles.module.css';

const EditEmployee = () => {
  const { id } = useParams<{ id?: string }>();

  const { usersEmployee } = useTypedSelector((state) => state.userProfile);
  const { isSaving } = useTypedSelector((store) => store.employee);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    const employee = usersEmployee.find((user) => user.id === Number(id));

    if (!employee) {
      return;
    }

    form.setFieldsValue({
      ...employee,
      supplierTypes: employee.supplierTypes.map(({ name }) => name),
    });
  }, [id, usersEmployee]);

  const handleFinish = (values: Omit<IEmployee, 'id'>) => {
    dispatch(saveEmployee({
      id: Number(id),
      ...values,
    }));
  };

  return (
    <div className={s.editBranch}>
      <div className={s.links}>
        <Link to={routes.personal}>
          <Button
            type="default"
            icon={<ContainerOutlined />}
            size="large"
          >
            Мои предложения
          </Button>
        </Link>
        <Link to={routes.settings}>
          <Button
            type="default"
            icon={<SettingOutlined />}
            size="large"
          >
            Настройки
          </Button>
        </Link>
      </div>
      <div className={s.header}>
        <h1 className={s.title}>
          {!id && 'Добавление сотрудника'}
          {id && 'Редактирование сотрудника'}
        </h1>
      </div>
      <Form
        className={s.form}
        form={form}
        onFinish={handleFinish}
      >
        <AddProviderBranchForm />
        <button
          type="submit"
          className={s.button}
          disabled={isSaving}
        >
          <span className={cx(s.buttonContent, isSaving && s.buttonContentHidden)}>
            {!id && 'Добавить сотрудника'}
            {id && 'Сохранить'}
          </span>
          {isSaving && (
            <span className={s.buttonLoader}>
              <LoadingButton />
            </span>
          )}
        </button>
      </Form>
    </div>
  );
};

export default EditEmployee;
