import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import useTypedSelector from 'src/hooks/useTypedSelector';

import routes from 'src/routes';

import Loader from '../shared-components/Loader/Loader';

import NavProfile from '../layout-components/Header/NavProfile';

import MarketHeaderForm from './MarketHeaderForm';

import s from './index.module.css';

const MarketHeader = () => {
  const { isAuth, isFetching } = useTypedSelector((state) => state.auth);

  return (
    <header className={s.header}>
      <div className={s.left}>
        <Link to={routes.index} className={s.logo}>
          DentMarketPlace
        </Link>
        <MarketHeaderForm />
      </div>
      <div className={s.right}>
        {isFetching && (
          <div className={s.loader}>
            <Loader />
          </div>
        )}
        {isAuth && (
          <div className={cx(s.nav, isFetching && s.navVisuallyHidden)}>
            <NavProfile />
          </div>
        )}
      </div>
    </header>
  );
};

export default MarketHeader;
