export enum ActionsTypes {
  SAVE_EMPLOYEE = 'SAVE_EMPLOYEE',
  SAVE_EMPLOYEE_REQUEST = 'SAVE_EMPLOYEE_REQUEST',
  SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS',
  SAVE_EMPLOYEE_FAIL = 'SAVE_EMPLOYEE_FAIL',
}

export interface EmployeeState {
  isSaving: boolean;
}

export interface SaveEmployee {
  type: ActionsTypes.SAVE_EMPLOYEE,
}

export interface SaveEmployeeRequest {
  type: ActionsTypes.SAVE_EMPLOYEE_REQUEST,
}

export interface SaveEmployeeSuccess {
  type: ActionsTypes.SAVE_EMPLOYEE_SUCCESS,
}

export interface SaveEmployeeFail {
  type: ActionsTypes.SAVE_EMPLOYEE_FAIL,
}

export type EmployeeActions = (
  SaveEmployee
  | SaveEmployeeRequest
  | SaveEmployeeSuccess
  | SaveEmployeeFail
);
