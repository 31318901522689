import React, { useEffect } from 'react';
import { parse } from 'query-string';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setCurrentModal } from 'src/store/action-creators/popUp';

import LoaderCentered from 'src/components/shared-components/Loader/LoaderCentered';

import { authApi } from 'src/api/api';

import routes from 'src/routes';

import { OAuthServices, UserTypes } from 'src/constants/enums';

import { ModalsTypes } from 'src/types/popUp';

const ExternalAuth = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { service } = useParams<{ service: string }>();

  const login = async (body: {
    googleCode?: string;
    yandexCode?: string;
  }) => {
    const { data } = await authApi.loginViaOauth(body);

    const { type }: { type: UserTypes } = jwtDecode(data.token);

    localStorage.setItem('token', data.token);

    // что???
    if (type !== UserTypes.Undefined) {
      window.location.href = routes.index;
    } else {
      dispatch(setCurrentModal(ModalsTypes.externalAuth));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { code } = parse(window.location.search);

        if (!code) {
          throw 1;
        }

        if (service === OAuthServices.Yandex) {
          await login({
            yandexCode: code as string,
          });
        } else {
          await login({
            googleCode: code as string,
          });
        }
      } catch (e) {
        console.warn(e);

        message.error('Произошла ошибка при авторизации');

        history.push(routes.index);
      }
    })();
  }, [service]);

  return (
    <LoaderCentered />
  );
};

export default ExternalAuth;
