import {
  ActionsTypes,
  EmployeeActions,
  EmployeeState,
} from 'src/types/employee';

const initialState: EmployeeState = {
  isSaving: false,
};

const employeeReducer = (
  state = initialState,
  action: EmployeeActions,
): EmployeeState => {
  switch (action.type) {
    case ActionsTypes.SAVE_EMPLOYEE_REQUEST: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case ActionsTypes.SAVE_EMPLOYEE_SUCCESS:
    case ActionsTypes.SAVE_EMPLOYEE_FAIL: {
      return {
        ...state,
        isSaving: false,
      };
    }

    default:
      return state;
  }
};

export default employeeReducer;
