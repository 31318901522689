import {
  ChangeEvent,
  useEffect, useRef, useState,
} from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { usersApi } from 'src/api/api';

import useDebounce from 'src/hooks/useDebounce';
import useOnClickOutside from 'src/hooks/useOnClickOutside';

const useMarketHeader = () => {
  const [query] = useQueryParams({
    search: StringParam,
  });

  const [search, setSearch] = useState(query.search || '');

  const [tips, setTips] = useState({
    hints: [],
    history: [],
  });

  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  const [deletingHistoryHint, setDeletingHistoryHint] = useState<string | null>(null);

  const tipsMenuRef = useRef(null);

  const isFilterEditing = useRef(false);

  const setIsFilterEditing = (value: boolean) => {
    isFilterEditing.current = value;
  };

  const debounceSearch = useDebounce(search, 1000);

  useEffect(() => {
    if (deletingHistoryHint) {
      return undefined;
    }

    if (!debounceSearch || !isFilterEditing.current) {
      setTips({
        hints: [],
        history: [],
      });

      return undefined;
    }

    setStatus('loading');
    setError(null);

    usersApi.getMarketHints({ search: debounceSearch })
      .then((e) => {
        setTips(e.data);
      })
      .catch((e) => {
        console.warn(e);

        setError(e);
      })
      .finally(() => {
        setStatus('loaded');
      });

    return () => {};
  }, [debounceSearch, deletingHistoryHint]);

  useOnClickOutside(tipsMenuRef, () => {
    setTimeout(() => {
      isFilterEditing.current = false;

      setTips({
        hints: [],
        history: [],
      });
    }, 0);
  });

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsFilterEditing(true);

    setSearch(target.value);
  };

  const onDelete = (item: string) => {
    setDeletingHistoryHint(item);

    usersApi.deleteMarketHint({ search: item })
      .then(() => {
        setDeletingHistoryHint(null);
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  return {
    search,
    setSearch,

    tips,
    status,
    error,

    tipsMenuRef,

    isFilterEditing: isFilterEditing.current,
    setIsFilterEditing,

    onChange,
    onDelete,

    deletingHistoryHint,
    setDeletingHistoryHint,
  };
};

export default useMarketHeader;
