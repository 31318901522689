/* eslint-disable prefer-regex-literals */
import React, { FC } from 'react';
import InputMask from 'react-input-mask';

import {
  Checkbox,
  Form,
  Input,
} from 'antd';

import { UserTypes } from 'src/constants/enums';
import useTypedSelector from 'src/hooks/useTypedSelector';

import s from '../Settings.module.css';
import '../Setting.css';

interface ProviderFormProps {
  onChangeProviderTypes: (e: any) => void;
}

const ProviderForm: FC<ProviderFormProps> = (props) => {
  const { onChangeProviderTypes } = props;

  const userData = useTypedSelector((state) => state.userProfile);

  const canEdit = userData.type === UserTypes.Provider;

  return (
    <div className={s.changeUserInfoGroupItems}>
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Имя</span>
        <Form.Item
          className={s.inputDescription}
          name="name"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите имя',
            },
            {
              pattern: new RegExp('^[А-Яа-я]+$'),
              message: 'Пожалуйста, введите имя кириллицей',
            },
          ]}
        >
          <Input
            id="name"
            type="text"
            placeholder="Имя"
            maxLength={20}
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
      <div className={s.inputGroupItem}>
        <span className={s.inputDescription}>Телефон</span>
        <Form.Item
          className={s.inputDescription}
          name="phone"
          rules={[{
            required: true,
            message: 'Пожалуйста, введите номер телефона',
          }]}
        >
          <InputMask
            placeholder="Телефон"
            className="ant-input"
            id="phone"
            mask="+7(999) 999-9999"
            disabled={!canEdit}
          />
        </Form.Item>
      </div>
      {canEdit && (
        <>
          <div className={s.inputGroupItem}>
            <span className={s.inputDescription}>Организация</span>
            <Form.Item
              className={s.inputDescription}
              name="organization"
              rules={[{
                required: true,
                message: 'Пожалуйста, введите наименование организации',
              }]}
            >
              <Input
                maxLength={200}
                id="organization"
                type="text"
                placeholder="Организация"
                disabled={!canEdit}
              />
            </Form.Item>
          </div>
          <div className={s.inputGroupItem}>
            <span className={s.inputDescription}>Сайт компании</span>
            <Form.Item
              className={s.formItem}
              name="website"
              rules={[
                {
                  message: 'Пожалуйста, введите ссылку на сайт корректно!',
                  pattern: new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\=]*)?/gi),
                },
              ]}
            >
              <Input
                maxLength={200}
                id="website"
                type="text"
                placeholder="Сайт компании"
                disabled={!canEdit}
              />
            </Form.Item>
          </div>
        </>
      )}
      <div style={{ width: 200 }} className={s.inputGroupItem}>
        <span className={s.inputDescription}>Тип поставщика</span>
        <Form.Item
          className={s.formItem}
          name="providerTypes"
        >
          <Checkbox.Group
            options={[
              {
                label: 'Оборудование',
                value: 'Оборудование',
              },
              {
                label: 'Материалы',
                value: 'Материалы',
              },
              {
                label: 'Инструменты',
                value: 'Инструменты',
              },
              {
                label: 'Хоз. товары',
                value: 'Хоз. товары',
              },
              {
                label: 'Ортодонтия',
                value: 'Ортодонтия',
              },
              {
                label: 'Имплантология',
                value: 'Имплантология',
              },
              {
                label: 'Расходники',
                value: 'Расходники',
              },
              {
                label: 'Гигиена',
                value: 'Гигиена',
              },
            ]}
            disabled={!canEdit}
            onChange={onChangeProviderTypes}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ProviderForm;
