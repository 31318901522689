import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  message,
} from 'antd';

import useTypedSelector from 'src/hooks/useTypedSelector';

import { ModalsTypes } from 'src/types/popUp';

import { SupplierTypes, SupplierTypesOptions, UserTypes } from 'src/constants/enums';

import routes from 'src/routes';

import { authApi } from 'src/api/api';

import common from '../Modals.module.css';

import s from './index.module.css';

interface IAuthForm {
  organization: string;
  userType: UserTypes.Customer | UserTypes.Provider;
  providerType: SupplierTypes[];
}

const ExteranalAuthModal = () => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState(UserTypes.Customer);

  const pupups = useTypedSelector((state) => state.popUps);

  useEffect(() => {
    setVisible(pupups.currentModal === ModalsTypes.externalAuth);
  }, [pupups]);

  const [form] = Form.useForm();

  const handleChange = (changedValues: IAuthForm) => {
    if (changedValues.userType) {
      setUserType(changedValues.userType);

      if (changedValues.userType) {
        form.setFieldsValue({
          providerType: [],
        });
      }
    }
  };

  const handleSubmit = async (values: IAuthForm) => {
    setIsLoading(true);

    try {
      const { data } = await authApi.registerViaOauth(values);

      localStorage.setItem('token', data.token);

      window.location.href = routes.index;
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при регистрации');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      centered
      title="Регистрация"
      visible={visible}
      footer={null}
      width="600px"
      maskClosable={false}
      closable={false}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={handleChange}
      >
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription}>
            Наименование компании
          </span>
          <Form.Item
            className={s.formItem}
            name="organization"
          >
            <Input
              placeholder="Наименование компании"
              maxLength={50}
            />
          </Form.Item>
        </div>
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription} />
          <Form.Item
            className={s.formItem}
            name="userType"
            initialValue={userType}
          >
            <Radio.Group>
              <Radio
                value={UserTypes.Customer}
              >
                Заказчик
              </Radio>
              <Radio
                value={UserTypes.Provider}
              >
                Поставщик
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        {userType === UserTypes.Provider && (
          <div className={s.inputGroupItem}>
            <span className={s.inputDescription}>
              Тип поставщика
            </span>
            <Form.Item
              className={s.formItem}
              name="providerType"
            >
              <Checkbox.Group
                options={SupplierTypesOptions}
              />
            </Form.Item>
          </div>
        )}
        <Button
          type="primary"
          className={common.button}
          loading={isLoading}
          htmlType="submit"
        >
          Войти
        </Button>
      </Form>
    </Modal>
  );
};

export default ExteranalAuthModal;
