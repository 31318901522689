/* eslint-disable import/no-cycle */
import { Dispatch } from 'react';
import {
  LogIn,
  ActionsTypes,
  AuthActions,
  IsetEmailVerified,
  ISetIsFetching,
} from '../../types/auth';
import { authApi } from '../../api/api';
import { showErr } from '../action-creators/popUp';
import { getUserData } from '../userProfile/actions';
import getCookie from '../../helpers/getCookie';

export const logInAC = (payload: { isAuth: boolean }): LogIn => ({
  type: ActionsTypes.LOG_IN,
  payload,
});
export const setIsFetching = (payload: boolean): ISetIsFetching => ({
  type: ActionsTypes.SET_IS_FETCHING,
  payload,
});
export const setIsLoading = (payload: boolean): any => ({
  type: ActionsTypes.SET_IS_LOADING,
  payload,
});
export const setIsEmailVerified = (payload: boolean): IsetEmailVerified => ({
  type: ActionsTypes.SET_EMAIL_VERIFIED,
  payload,
});

export const authUser = ({
  callback = () => { },
  ...data
}: {
  email?: string,
  password?: string,
  branchId?: number,
  employeeId?: number,
  callback?: () => void,
}) => (dispatch: Dispatch<any>) => {
  dispatch(setIsFetching(true));

  authApi
    .login(data)
    .then((response) => {
      localStorage.setItem('token', response.data.token);

      if (data.branchId) {
        localStorage.setItem('branchId', `${data.branchId}`);
      }

      if (data.employeeId) {
        localStorage.setItem('employeeId', `${data.employeeId}`);
      }

      // TODO: эта штука несколько раз при логине отправляется
      // возможно стоит оптимизировать
      dispatch(getUserData(response.data.token));

      callback();
    })
    .catch((e) => {
      console.log(e);
      dispatch(setIsFetching(false));
      dispatch(showErr('Произошла ошибка при авторизации'));
    });
};

export const logOut = () => (dispatch: Dispatch<AuthActions>) => {
  authApi.logOut().then((res) => {
    if (res.status === 200) {
      dispatch(logInAC({ isAuth: false }));
      localStorage.clear();
    }
  });
};

export const changeEmail = (token: string | undefined | null, email: string) => (
  (dispatch: Dispatch<any>) => {
    authApi
      .changeEmail(token, email)
      .then((res) => {
        if (res.data.result) {
          dispatch(showErr('Почта изменена!'));
          authApi.refresh().then(() => {
            const updatedToken: string | undefined | null = getCookie('Authentication');
            dispatch(getUserData(updatedToken || ''));
          });
        } else {
          dispatch(showErr(res.data.msg));
        }
      })
      .catch(() => {
        dispatch(showErr('Ошибка!'));
      });
  }
);

export const changePassword = (
  currentPassword: string,
  newPassword: string,
) => (dispatch: Dispatch<any>) => {
  authApi.changePassword(currentPassword, newPassword).then((res) => {
    if (res.data.result) {
      dispatch(showErr('Пароль обновлён!'));
      authApi.refresh().then(() => {
        const updatedToken: string | undefined | null = getCookie('Authentication');
        dispatch(getUserData(updatedToken || ''));
      });
    } else {
      dispatch(showErr(res.data.msg));
    }
  });
};
