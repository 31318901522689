import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MarketFilters from 'src/components/MarketFilters';

import routes from 'src/routes';

import MarketGood from '../MarketGood';
import MarketMain from '../MarketMain';

import s from './index.module.css';

const Market = () => (
  <div className={s.page}>
    <div className={s.pageBody}>
      <div className={s.pageFilter}>
        <MarketFilters />
      </div>
      <div className={s.pageContent}>
        <Switch>
          <Route exact path={routes.market} component={MarketMain} />
          <Route exact path={routes.marketGood} component={MarketGood} />
        </Switch>
      </div>
    </div>
  </div>
);

export default Market;
