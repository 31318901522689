import React, { useEffect } from 'react';

import {
  Button,
  ConfigProvider,
  Form,
  Modal,
} from 'antd';

import { NavLink, useHistory, useParams } from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';
import { ContainerOutlined, TableOutlined } from '@ant-design/icons';
import cx from 'classnames';

import routes from 'src/routes';

import { LoadingButton } from 'src/components/shared-components/Loading';
import { LocationState } from 'src/constants/enums';

import MarketGoodForm from './MarketGoodForm';
import useEditPersonalMarketGood, { ValidationErrorTexts } from './useEditPersonalMarketGood';

import s from './index.module.css';

const EditPersonalMarketGood = () => {
  const { id } = useParams<{ id?: string }>();

  const [form] = Form.useForm();

  const {
    good,
    isSaving,
    files,
    invalidFiles,
    onSubmit,
    onBeforeUpload,
    onUpload,
    onRemove,
    isInvalidFilesModalOpened,
    setIsInvalidFilesModalOpened,
  } = useEditPersonalMarketGood();

  useEffect(() => {
    form.setFieldsValue(good);
  }, [good]);

  const history = useHistory<LocationState>();
  const { from = window.location.href } = history.location.state || {};
  const { pathname: comeFrom } = new URL(from);

  return (
    <ConfigProvider locale={ru_RU}>
      <div className={s.page}>
        <div className={s.pageBody}>
          <div className={s.links}>
            <NavLink
              className={s.link}
              to={routes.personal}
            >
              <Button
                type="default"
                icon={<ContainerOutlined />}
                size="large"
              >
                Мои предложения
              </Button>
            </NavLink>
            <NavLink
              className={s.link}
              to={routes.personalMarket.index}
            >
              <Button
                type="default"
                icon={<TableOutlined />}
                size="large"
              >
                Моя витрина
              </Button>
            </NavLink>
          </div>
          <div className={s.pageHeader}>
            <h1 className={s.pageTitle}>
              {!id && 'Добавление товара'}
              {id && 'Редактирование товара'}
            </h1>
          </div>
          <Form
            className={s.form}
            form={form}
            onFinish={onSubmit}
          >
            <MarketGoodForm
              files={files}
              onBeforeUpload={onBeforeUpload}
              onUpload={onUpload}
              onRemove={onRemove}
            />
            <div className={s.buttons}>
              <button
                type="submit"
                className={s.button}
                disabled={isSaving}
              >
                <span className={cx(s.buttonContent, isSaving && s.buttonContentHidden)}>
                  {!id && 'Отправить на модерацию'}
                  {id && 'Сохранить'}
                </span>
                {isSaving && (
                  <span className={s.buttonLoader}>
                    <LoadingButton />
                  </span>
                )}
              </button>
              {comeFrom === routes.personalMarket.index && (
                <Button
                  size="large"
                  onClick={() => { history.goBack(); }}
                >
                  Назад
                </Button>
              )}
            </div>
          </Form>
          <Modal
            centered
            title="Ошибки в файлах"
            visible={isInvalidFilesModalOpened}
            footer={null}
            onCancel={() => setIsInvalidFilesModalOpened(false)}
          >
            <ul>
              {invalidFiles.map((file) => (
                <li className={s.errorListItem}>
                  <span>{file.name}</span>
                  {file.errors?.map((error) => (
                    <p className={s.errorText}>{ValidationErrorTexts[error]}</p>
                  ))}
                </li>
              ))}
            </ul>
          </Modal>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default EditPersonalMarketGood;
