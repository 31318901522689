import {
  ActionsTypes,
  BranchesActions,
  BranchesState,
} from 'src/types/branches';

const initialState: BranchesState = {
  deletingBranchId: null,
  isCredentialsSaving: false,
  savingError: null,
};

const branchesReducer = (
  state = initialState,
  action: BranchesActions,
): BranchesState => {
  switch (action.type) {
    case ActionsTypes.DELETE_BRANCH_REQUEST: {
      return {
        ...state,
        deletingBranchId: action.payload.id,
      };
    }

    case ActionsTypes.DELETE_BRANCH_SUCCESS:
    case ActionsTypes.DELETE_BRANCH_FAIL: {
      return {
        ...state,
        deletingBranchId: null,
      };
    }

    case ActionsTypes.SAVE_BRANCH_CREDENTIALS_REQUEST: {
      return {
        ...state,
        isCredentialsSaving: true,
      };
    }

    case ActionsTypes.SAVE_BRANCH_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        isCredentialsSaving: false,
      };
    }

    case ActionsTypes.SAVE_BRANCH_CREDENTIALS_FAIL: {
      return {
        ...state,
        isCredentialsSaving: false,
        savingError: action.payload.error,
      };
    }

    default:
      return state;
  }
};

export default branchesReducer;
