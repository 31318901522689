import { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import routes from 'src/routes';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { usersApi } from 'src/api/api';

import { IMarketGood } from 'src/constants/enums';

import getFileResolution from 'src/helpers/getFileResolution';
import getBase64 from 'src/helpers/getBase64';
import applyQuery from 'src/helpers/applyQuery';

import { GoodUploadFile } from '../../constants/enums';

const ValidationErrorTypes = {
  ContentType: 'ContentType',
  Size: 'Size',
  Dimensions: 'Dimensions',
};

export const ValidationErrorTexts = {
  [ValidationErrorTypes.ContentType]: 'Загружаемый файл имеет неверный тип',
  [ValidationErrorTypes.Size]: 'Загружаемый файл не может быть больше размера 5МБ',
  [ValidationErrorTypes.Dimensions]: 'Загружаемый файл не может быть размера больше 500x500px',
};

const checkIsValidFile = async (uploadedFile: RcFile) => {
  const isCorrectContentType = ['image/png', 'image/jpg', 'image/jpeg']
    .includes(uploadedFile.type);

  if (!isCorrectContentType) {
    return [ValidationErrorTypes.ContentType];
  }

  if (uploadedFile.size >= 5 * 1024 * 1024) {
    return [ValidationErrorTypes.Size];
  }

  const dimensions = await getFileResolution(uploadedFile);

  if (dimensions.width > 500 && dimensions.height > 500) {
    return [ValidationErrorTypes.Dimensions];
  }

  return [];
};

// TODO: вынести получение товара в отдельный хук
const useEditPersonalMarketGood = () => {
  const [good, setGood] = useState<IMarketGood | null>(null);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const [validFiles, setValidFiles] = useState<GoodUploadFile[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<GoodUploadFile[]>([]);
  const [isInvalidFilesModalOpened, setIsInvalidFilesModalOpened] = useState(false);

  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!id) {
      return;
    }

    setStatus('loading');
    setError(null);

    usersApi.getMarketGood({
      id: Number(id),
    })
      .then((e) => {
        setGood(e.data);
      })
      .catch((e) => {
        console.warn(e);

        setError(e);
      })
      .finally(() => {
        setStatus('loaded');
      });
  }, [location, id]);

  const onSubmit = async (values: Omit<IMarketGood, 'id'>) => {
    setIsSaving(true);

    const formData = new FormData();

    if (values.description) {
      formData.append('description', values.description);
    }

    if (id) {
      formData.append('id', id);

      good?.files.forEach((file, index) => {
        formData.append(`unchangedFileIds[${index}]`, String(file.id));
      });
    }

    formData.append('name', values.name);

    if (values.code) {
      formData.append('code', values.code);
    }

    formData.append('type', values.type.name);
    formData.append('supplierType', values.supplierType.name);

    if (validFiles) {
      validFiles.forEach((file) => {
        formData.append('files', file.originFileObj as RcFile);
      });
    }

    try {
      if (!id) {
        await usersApi.createMarketGood(formData);
      } else {
        await usersApi.editMarketGood(formData);
      }

      message.success('Товар сохранен успешно!');

      history.push(applyQuery(routes.personalMarket.index));
    } catch (e) {
      message.error((e as AxiosError).response?.data?.message || 'Произошла ошибка при сохранении товара');
    } finally {
      setIsSaving(false);
    }
  };

  const onBeforeUpload = () => false;

  const onUpload = async (data: UploadChangeParam<UploadFile<File>> & { valid?: boolean }) => {
    const { fileList } = data;

    // TODO: переделать
    const {
      validFiles: newValidFiles,
      invalidFiles: newInvalidFiles,
    } = await (fileList as GoodUploadFile[])
      .slice(0, 4 - validFiles.length)
      .reduce(async (memo, newFile) => {
        const resolvedMemo = await memo;
        const errors = await checkIsValidFile(newFile.originFileObj as RcFile);

        if (errors.length > 0) {
          // eslint-disable-next-line no-param-reassign
          newFile.errors = errors;

          return {
            ...resolvedMemo,
            invalidFiles: [...resolvedMemo.invalidFiles, newFile],
          };
        }

        if (!newFile.url && !newFile.preview) {
          // eslint-disable-next-line no-param-reassign
          newFile.preview = await getBase64(newFile.originFileObj as RcFile);
        }

        // eslint-disable-next-line no-param-reassign
        newFile.id = Date.now();

        return {
          ...resolvedMemo,
          validFiles: [...resolvedMemo.validFiles, newFile],
        };
      }, Promise.resolve({
        validFiles: [],
        invalidFiles: [],
      } as {
        validFiles: GoodUploadFile[],
        invalidFiles: GoodUploadFile[],
      }));

    setValidFiles([...validFiles, ...newValidFiles]);
    setInvalidFiles(newInvalidFiles);

    if (newInvalidFiles.length > 0) {
      setIsInvalidFilesModalOpened(true);
    }
  };

  // TODO: чуть-чуть костыльно
  const onRemove = (fileId: number) => {
    setValidFiles(validFiles.filter((file) => file.id !== fileId));

    if (good) {
      setGood({
        ...good,
        files: good.files.filter((file) => file.id !== fileId),
      });
    }
  };

  return {
    good,
    status,
    error,
    onSubmit,
    isSaving,
    onBeforeUpload,
    onUpload,
    onRemove,
    files: (good?.files || []).concat(validFiles.map((file) => ({
      id: file.id,
      path: file.preview as string,
    }))),
    invalidFiles,
    isInvalidFilesModalOpened,
    setIsInvalidFilesModalOpened,
  };
};

export default useEditPersonalMarketGood;
