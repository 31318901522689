import React from 'react';
import InputMask from 'react-input-mask';
import { Input, Select } from 'antd';

import getCustomerPhone from 'src/helpers/getCustomerPhone';
import { UserTypes } from 'src/constants/enums';

import useTypedSelector from 'src/hooks/useTypedSelector';
import cities from '../../assets/data/cities';

import CustomProviders from './CustomProviders';

import s from './index.module.css';

const { Option } = Select;

interface CustomerProposalContactsProps {
  organization: string;
  organizationHandler: (value: string) => void;
  name: string;
  nameHandler: (value: string) => void;
  number: string;
  phoneHandler: (value: string) => void;
  emailHandler: (value: string) => void;
  fileData: File | null;
  setFileData: React.Dispatch<React.SetStateAction<File | null>>;
  supplierTypes: string[];
  setSupplierHandler: (value: string[]) => void;
  exceptionalSuppliers: never[];
  handleChange: (value: any) => void;
  uniqProviders: any;
  deliveryAddress: string;
  setDeliveryAddress: React.Dispatch<React.SetStateAction<string>>;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  linkTypeHandler: (value: string) => void;
  customProviderEmails: string[];
  setCustomProviderEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomerProposalContacts: React.FC<CustomerProposalContactsProps> = (props) => {
  const {
    organization,
    organizationHandler,
    name,
    nameHandler,
    number,
    phoneHandler,
    emailHandler,
    fileData,
    setFileData,
    supplierTypes,
    setSupplierHandler,
    exceptionalSuppliers,
    handleChange,
    uniqProviders,
    deliveryAddress,
    setDeliveryAddress,
    comment,
    setComment,
    region,
    setRegion,
    linkTypeHandler,
    customProviderEmails,
    setCustomProviderEmails,
  } = props;

  const { email, type, mainBranchName } = useTypedSelector((state) => state.userProfile);
  const userInfo = useTypedSelector((state) => state.userProfile);

  return (
    <div className={s.top_container}>
      <div className={s.form_header}>
        <span className={s.form_title}>
          Ваши данные
        </span>
      </div>
      <div className={s.inputs}>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Название филиала</div>
          <Input
            value={mainBranchName}
            disabled
          />
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Организация</div>
          {userInfo.organization !== '' && (
            <Input
              required
              maxLength={50}
              type="text"
              name="organization"
              value={organization}
              onChange={(e) => organizationHandler(e.target.value)}
              disabled={type !== UserTypes.Customer}
            />
          )}
          {userInfo.organization === '' && (
            <Input
              required
              maxLength={50}
              type="text"
              name="organization"
              defaultValue=""
              onChange={(e) => organizationHandler(e.target.value)}
              disabled={type !== UserTypes.Customer}
            />
          )}
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Имя</div>
          {userInfo.name !== '' && (
            <Input
              pattern="^[а-яА-Я]+\s*$"
              title="Пожалуйста, введите свое имя кириллицей без пробелов!"
              required
              maxLength={20}
              type="text"
              name="name"
              value={name}
              onChange={(e) => nameHandler(e.target.value)}
              disabled={type !== UserTypes.Customer}
            />
          )}

          {userInfo.name === '' && (
            <Input
              required
              maxLength={20}
              type="text"
              name="name"
              defaultValue=""
              onChange={(e) => nameHandler(e.target.value)}
              disabled={type !== UserTypes.Customer}
            />
          )}
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Телефон</div>
          {userInfo.phone !== '' && (
            <InputMask
              mask="+7(999) 999-9999"
              required
              type="tel"
              name="number"
              value={number}
              onChange={(e) => phoneHandler(e.target.value)}
              beforeMaskedValueChange={getCustomerPhone}
              disabled={type !== UserTypes.Customer}
            />
          )}

          {userInfo.phone === '' && (
            <InputMask
              mask="+7(999) 999-9999"
              required
              type="tel"
              name="number"
              defaultValue=""
              onChange={(e) => phoneHandler(e.target.value)}
              disabled={type !== UserTypes.Customer}
            />
          )}
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Электронная почта</div>
          <Input
            required
            disabled
            type="email"
            name="email"
            value={email || ''}
            onChange={(e) => emailHandler(e.target.value)}
          />
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Реквизиты организации</div>
          <div className={s.upload_container}>
            <label htmlFor="file">
              {fileData
                ? fileData.name
                : 'Загрузить файл (.doc, .pdf, .jpg)'}
            </label>
            <Input
              type="file"
              id="file"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/pdf, image/jpeg, image/png, image/jpg"
              onChange={(e) => setFileData(e.target.files ? e.target.files[0] : null)}
            />
          </div>
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Предпочтитаемый способ связи с поставщиком</div>
          <select
            required
            name="contact_way"
            onChange={(e) => linkTypeHandler(e.target.value)}
          >
            <option value="E-mail">E-mail</option>
            <option value="WhatsApp">WhatsApp</option>
            <option value="Viber">Viber</option>
            <option value="Telegram">Telegram</option>
          </select>
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Тип поставщика</div>
          <Select
            showArrow
            mode="multiple"
            showSearch={false}
            allowClear
            placeholder="Выберите тип поставщика"
            onChange={(e: any) => setSupplierHandler(e)}
            value={supplierTypes}
            className={s.select}
          >
            <Option value="Материалы">Материалы</Option>
            <Option value="Оборудование">Оборудование</Option>
            <Option value="Инструменты">Инструменты</Option>
            <Option value="Хоз. товары">Хоз. товары</Option>
            <Option value="Ортодонтия">Ортодонтия</Option>
            <Option value="Имплантология">Имплантология</Option>
            <Option value="Расходники">Расходники</Option>
            <Option value="Гигиена">Гигиена</Option>
          </Select>
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>
            Другие поставщики
          </div>
          <div className={s.input_item_providers}>
            <CustomProviders
              customProviderEmails={customProviderEmails}
              setCustomProviderEmails={setCustomProviderEmails}
            />
          </div>
        </div>
        <div className={`${s.input_item} ${s.uniq_providers}`}>
          <div className={s.input_item_title}>Исключительные поставщики</div>
          <Select
            showArrow
            mode="multiple"
            showSearch={false}
            allowClear
            value={exceptionalSuppliers.map((item: any) => item)}
            disabled={supplierTypes.length === 0}
            placeholder="Выберите исключительных поставщиков"
            onChange={(e: any) => handleChange(e)}
            className={s.select}
          >
            {uniqProviders.items?.map((item: any) => [
              <Option
                key={item.id}
                value={item.id}
              >
                {item.organization !== null ? item.organization : item.email}
              </Option>,
            ])}
          </Select>
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Регион доставки</div>
          <select
            required
            name="provider_type"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            disabled={type !== UserTypes.Customer}
          >
            {cities.map((city) => (
              <option key={city}>{city}</option>
            ))}
          </select>
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Адрес доставки</div>
          <Input.TextArea
            value={deliveryAddress}
            name="address"
            onChange={(e) => setDeliveryAddress(e.target.value)}
            required
            maxLength={255}
            disabled={type !== UserTypes.Customer}
          />
        </div>
        <div className={s.input_item}>
          <div className={s.input_item_title}>Комментарий в свободной форме</div>
          <Input.TextArea
            value={comment}
            name="comment"
            onChange={(e) => setComment(e.target.value)}
            maxLength={255}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerProposalContacts;
