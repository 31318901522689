/* eslint-disable import/prefer-default-export */
import { IBackendBranch, IBranch } from 'src/constants/enums';
import history from 'src/helpers/history';
import routes from 'src/routes';

import {
  BranchActions,
  ActionsTypes,
  SaveBranchRequest,
  SaveBranchSuccess,
  SaveBranchFail,
} from 'src/types/branch';

import { AppThunk } from '..';

import { usersApi } from '../../api/api';
import { showErr } from '../action-creators/popUp';
import { mapBranchToBackendFormat } from './helpers';

const makeRequest = (branch: IBackendBranch) => {
  if (branch.id) {
    return usersApi.updateBranch(branch);
  }

  const {
    id,
    ...other
  } = branch;

  return usersApi.createBranch(other);
};

const saveBranchRequest = (): SaveBranchRequest => ({
  type: ActionsTypes.SAVE_BRANCH_REQUEST,
});

const saveBranchSuccess = (): SaveBranchSuccess => ({
  type: ActionsTypes.SAVE_BRANCH_SUCCESS,
});

const saveBranchFail = (): SaveBranchFail => ({
  type: ActionsTypes.SAVE_BRANCH_FAIL,
});

export const saveBranch = (
  branch: IBranch,
): AppThunk<BranchActions> => (dispatch) => {
  dispatch(saveBranchRequest());

  makeRequest(mapBranchToBackendFormat(branch))
    .then((response) => {
      if (!response.data.result) {
        throw response;
      }

      dispatch(saveBranchSuccess());

      dispatch(showErr('Настройки сохранены успешно!'));

      history.push(routes.branches.index);
    })
    .catch((e) => {
      console.warn(e);

      dispatch(saveBranchFail());

      dispatch(showErr('Произошла ошибка при сохранении!'));
    });
};
