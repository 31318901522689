import React from 'react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';

import GoodsTable from './GoodsTable';

import s from './index.module.css';

const MarketModeration = () => (
  <ConfigProvider locale={ru_RU}>
    <div className={s.page}>
      <div className={s.pageBody}>
        <div className={s.pageHeader}>
          <h1 className={s.pageTitle}>Модерация товаров</h1>
        </div>
        <GoodsTable />
      </div>
    </div>
  </ConfigProvider>
);

export default MarketModeration;
