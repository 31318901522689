/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-regex-literals */
import React from 'react';

import {
  Checkbox,
  Form,
  Input,
} from 'antd';

import InputMask from 'react-input-mask';

import { SupplierTypesOptions } from 'src/constants/enums';

import s from './styles.module.css';

const AddProviderBranchForm = () => (
  <div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Имя сотрудника</span>
      <Form.Item
        className={s.formItem}
        name="name"
        rules={[{
          required: true,
          message: 'Пожалуйста, введите свое имя кириллицей!',
          pattern: new RegExp('^[А-Яа-я]+$'),
        }]}
      >
        <Input
          placeholder="Имя сотрудника"
          maxLength={200}
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Телефон</span>
      <Form.Item
        className={s.formItem}
        name="phone"
        rules={[{
          required: true,
          message: 'Заполните телефон',
        }]}
      >
        <InputMask
          className="ant-input"
          placeholder="Телефон"
          mask="+7(999) 999-9999"
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Тип поставщика</span>
      <Form.Item
        className={s.formItem}
        name="supplierTypes"
        rules={[{
          required: true,
          message: 'Укажите тип поставщика',
        }]}
      >
        <Checkbox.Group
          options={SupplierTypesOptions}
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>
        Электронная почта
      </span>
      <Form.Item
        className={s.formItem}
        name="email"
        rules={[{
          required: true,
          message: 'Пожалуйста, введите свою почту корректно!',
          pattern: new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/),
        }]}
      >
        <Input
          placeholder="Электронная почта"
          maxLength={50}
        />
      </Form.Item>
    </div>
  </div>
);

export default AddProviderBranchForm;
