/* eslint-disable prefer-regex-literals */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useEffect, useState } from 'react';
import CalendarLocale from 'rc-picker/lib/locale/ru_RU';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _pick from 'lodash/pick';
import _sumBy from 'lodash/sumBy';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import _merge from 'lodash/merge';
import { CopyOutlined } from '@ant-design/icons';

import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Table,
  Typography,
  Select,
} from 'antd';

import {
  DeliveryTypes, DeliveryTypesLabels, Invoice,
} from 'src/constants/enums';

import { usersApi } from 'src/api/api';

import { LoadingButton } from 'src/components/shared-components/Loading';

import { ColumnGroupType } from 'antd/es/table';

import { TInvoiceForm, TInvoiceTableRecord } from './types';

import s from './index.module.css';

const GOOD_TEMPLATE = {
  name: '',
  amount: 0,
  price: 0,
  comment: '',
};

interface UploadInvoiceFormProps {
  invoice: Invoice;
  onSubmit: () => void;
}

const UploadInvoiceForm: FC<UploadInvoiceFormProps> = (props) => {
  const { invoice, onSubmit } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [invoiceGoodsAmount, setInvoiceGoodsAmount] = useState(0);
  const [invoiceGoodsPrice, setInvoiceGoodsPrice] = useState(0);

  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm<TInvoiceForm>();

  const updateSummaryData = () => {
    const { goods } = form.getFieldsValue();

    setInvoiceGoodsAmount(_sumBy(goods, 'amount'));
    setInvoiceGoodsPrice(_sumBy(goods, (o) => o.price * o.amount));
  };

  const handleNotDeterminedGoodChange = (value: string) => {
    const { goods } = form.getFieldsValue();

    const notDetermineGood = invoice.determineFailed
      .find(({ name }) => name === value)!;

    form.setFieldsValue({
      goods: goods.map((good) => (
        good.name === value
          ? _merge({}, notDetermineGood, { comment: good.comment })
          : good
      )),
    });

    updateSummaryData();
  };

  useEffect(() => {
    if (invoice) {
      form.resetFields();

      form.setFieldsValue({
        goods: invoice.goods.map(({ match, ...good }) => (
          _merge(
            {},
            GOOD_TEMPLATE,
            _pick(match || good, ['name', 'amount', 'price']),
          )
        )),
        providerInfo: invoice.providerInfo,
      });

      updateSummaryData();
    }
  }, [invoice]);

  const handleFinish = async (values: TInvoiceForm) => {
    try {
      setIsSaving(true);

      await usersApi.compareProvider({
        id: Number(id),
        provider: _pickBy(values.providerInfo, _identity),
        goods: invoice.goods.map(({ id: productId, amount: originalAmount }, index) => {
          const {
            amount,
            price,
            comment,
          } = values.goods[index];

          return {
            productId,
            price,
            comment: comment.length === 0
              ? null
              : comment,
            amount: originalAmount === amount
              ? null
              : amount,
          };
        }),
      });

      onSubmit();

      message.success('Счет успешно добавлен!');
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при добавлении счета');
    } finally {
      setIsSaving(false);
    }
  };

  const data: TInvoiceTableRecord[] = invoice.goods.map((good, index) => {
    const { goods } = form.getFieldsValue();

    return {
      key: index,
      originalName: good.name,
      originalAmount: good.amount,
      isMatched: Boolean(good.match),
      ..._get(goods, [index]),
    };
  });

  const columns: ColumnGroupType<TInvoiceTableRecord>[] = [
    {
      title: 'Ваша заявка',
      children: [
        {
          title: 'Наименование товара',
          key: 'originalName',
          dataIndex: 'originalName',
          width: 250,
        },
        {
          title: 'Кол-во',
          key: 'originalAmount',
          dataIndex: 'originalAmount',
          width: 50,
        },
      ],
    },
    {
      title: 'Сопоставленные данные из счета',
      children: [
        {
          title: 'Наименование товара',
          key: 'name',
          width: 300,
          render: (_, record, index) => (
            <div className={s.tableFormItemContainer}>
              <Form.Item
                style={{ maxWidth: '300px' }}
                className={s.tableFormItem}
                name={['goods', index, 'name']}
              >
                {record.isMatched ? (
                  <Input
                    required
                    placeholder="Введите наименование товара"
                    maxLength={200}
                  />
                ) : (
                  <Select
                    placeholder="Выберите наименование товара"
                    onChange={handleNotDeterminedGoodChange}
                  >
                    {invoice.determineFailed.map((good) => (
                      <Select.Option
                        key={good.id}
                        value={good.name}
                      >
                        {good.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Button onClick={() => navigator.clipboard.writeText(record.name)}>
                <CopyOutlined />
              </Button>
            </div>
          ),
        },
        {
          title: 'Кол-во',
          key: 'amount',
          width: 50,
          render: (_, __, index) => (
            <Form.Item
              className={s.tableFormItem}
              name={['goods', index, 'amount']}
            >
              <InputNumber
                required
                min={1}
              />
            </Form.Item>
          ),
        },
        {
          title: 'Цена, руб.',
          key: 'price',
          dataIndex: 'price',
          width: 50,
          render: (_, __, index) => (
            <Form.Item
              className={s.tableFormItem}
              name={['goods', index, 'price']}
            >
              <InputNumber
                min={0}
                required
              />
            </Form.Item>
          ),
        },
        {
          title: 'Комментарий',
          width: 200,
          render: (_, __, index) => (
            <Form.Item
              className={s.tableFormItem}
              name={['goods', index, 'comment']}
            >
              <Input
                placeholder="Добавьте комментарий"
                maxLength={200}
              />
            </Form.Item>
          ),
        },
      ],
    },
  ];

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onValuesChange={() => {
        updateSummaryData();
      }}
    >
      <div className={s.table}>
        <Table
          dataSource={data}
          pagination={false}
          columns={columns}
          size="small"
          rowClassName={(record) => cx(
            s.tableRow,
            !record.isMatched && !record.price && s.tableRowNotMatched,
          )}
          bordered
          // eslint-disable-next-line react/no-unstable-nested-components
          summary={(pageData) => {
            const proposalGoodsCount = _sumBy(pageData, 'originalAmount');

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}>
                  <Typography.Text strong>
                    Итого
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Text strong>
                    {proposalGoodsCount}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    {invoiceGoodsAmount}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <Typography.Text strong>
                    {invoiceGoodsPrice}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} />
              </Table.Summary.Row>
            );
          }}
        />
      </div>
      <h2 className={s.formTitle}>
        Проверьте данные поставщика
      </h2>
      <div className={s.form}>
        <div className={s.inputGroups}>
          <div className={s.inputGroupItem}>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Организация
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'organization']}
                rules={[{
                  required: true,
                  message: 'Введите наименование организации',
                }]}
              >
                <Input
                  placeholder="Введите наименование организации"
                  maxLength={50}
                />
              </Form.Item>
            </div>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Способ доставки
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'deliveryType']}
              >
                <Select placeholder="Выберите способ доставки">
                  <Select.Option value={DeliveryTypesLabels[DeliveryTypes.Courier]}>
                    Курьер
                  </Select.Option>
                  <Select.Option value={DeliveryTypesLabels[DeliveryTypes.TransportCompany]}>
                    Транспортная компания
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className={s.inputGroupItem}>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Дата доставки
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'deliveryDate']}
              >
                <DatePicker
                  className="ant-input"
                  format="DD.MM.YYYY"
                  disabledDate={(current) => current && current < moment().endOf('day')}
                  locale={{
                    lang: {
                      placeholder: 'Выберите дату',
                      yearPlaceholder: 'Выберите год',
                      quarterPlaceholder: 'Выберите квартал',
                      monthPlaceholder: 'Выберите месяц',
                      weekPlaceholder: 'Выберите неделю',
                      rangePlaceholder: ['Начальная дата', 'Конечная дата'],
                      rangeYearPlaceholder: ['Начальный год', 'Год окончания'],
                      rangeMonthPlaceholder: ['Начальный месяц', 'Конечный месяц'],
                      rangeWeekPlaceholder: ['Начальная неделя', 'Конечная неделя'],
                      ...CalendarLocale,
                    },
                    dateFormat: 'YYYY-MM-DD',
                  } as PickerLocale}
                />
              </Form.Item>
            </div>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Имя
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'name']}
              >
                <Input
                  placeholder="Введите имя"
                  maxLength={20}
                />
              </Form.Item>
            </div>
          </div>
          <div className={s.inputGroupItem}>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Cайт
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'website']}
                rules={[{
                  pattern: new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\=]*)?/gi),
                  message: 'Пожалуйста, введите ссылку на сайт корректно!',
                }]}
              >
                <Input
                  maxLength={200}
                  placeholder="Сайт компании"
                />
              </Form.Item>
            </div>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Электронная почта
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'email']}
                rules={[
                  {
                    required: true,
                    message: 'Введите почту',
                  }, {
                    pattern: new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/),
                    message: 'Некорректная почта',
                  },
                ]}
              >
                <Input
                  placeholder="Электронная почта"
                  maxLength={50}
                />
              </Form.Item>
            </div>
          </div>
          <div className={s.inputGroupItem}>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Стоимость доставки
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'deliveryPrice']}
              >
                <InputNumber
                  min={0}
                  defaultValue={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </div>
            <div className={s.inputGroupFlex}>
              <span className={s.inputDescription}>
                Телефон
              </span>
              <Form.Item
                className={s.formItem}
                name={['providerInfo', 'phone']}
                rules={[{
                  pattern: new RegExp(/^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gi),
                  message: 'Некорректный номер',
                }]}
              >
                <InputMask
                  className="ant-input"
                  mask="+7(999) 999-9999"
                  type="tel"
                  placeholder="Введите номер телефона"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <Button
        htmlType="submit"
        type="primary"
        disabled={isSaving}
      >
        <span className={cx(isSaving && s.buttonContentHidden)}>
          Добавить к сравнению
        </span>
        {isSaving && (
          <span className={s.buttonLoader}>
            <LoadingButton />
          </span>
        )}
      </Button>
    </Form>
  );
};

export default UploadInvoiceForm;
