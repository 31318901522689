/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

import {
  IEmployee, IEmployeeCredentials,
} from 'src/constants/enums';

import {
  EmployeesActions,
  ActionsTypes,
  DeleteEmployeeRequest,
  DeleteEmployeeSuccess,
  DeleteEmployeeFail,
  SaveEmployeeCredentialsRequest,
  SaveEmployeeCredentialsSuccess,
  SaveEmployeeCredentialsFail,
} from 'src/types/employees';

import { AppThunk } from '..';

import { usersApi } from '../../api/api';
import { showErr } from '../action-creators/popUp';

const deleteEmployeeRequest = (data: Pick<IEmployee, 'id'>): DeleteEmployeeRequest => ({
  type: ActionsTypes.DELETE_EMPLOYEE_REQUEST,
  payload: data,
});

const deleteEmployeeSuccess = (): DeleteEmployeeSuccess => ({
  type: ActionsTypes.DELETE_EMPLOYEE_SUCCESS,
});

const deleteEmployeeFail = (): DeleteEmployeeFail => ({
  type: ActionsTypes.DELETE_EMPLOYEE_FAIL,
});

export const deleteEmployee = (
  data: Pick<IEmployee, 'id'>,
): AppThunk<EmployeesActions> => (dispatch) => {
  dispatch(deleteEmployeeRequest(data));

  usersApi.deleteEmployee(data)
    .then(() => {
      dispatch(deleteEmployeeSuccess());

      dispatch(showErr('Филиал удален успешно!'));
    })
    .catch((e) => {
      console.warn(e);

      dispatch(deleteEmployeeFail());

      dispatch(showErr('Произошла ошибка при удалении'));
    });
};

const saveEmployeeCredentialsRequest = (): SaveEmployeeCredentialsRequest => ({
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_REQUEST,
});

const saveEmployeeCredentialsSuccess = (): SaveEmployeeCredentialsSuccess => ({
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_SUCCESS,
});

const saveEmployeeCredentialsFail = (payload: { error: string }): SaveEmployeeCredentialsFail => ({
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_FAIL,
  payload,
});

export const saveEmployeeCredentials = (
  credentials: IEmployeeCredentials,
): AppThunk<EmployeesActions> => (dispatch) => {
  dispatch(saveEmployeeCredentialsRequest());

  usersApi.saveEmployeeCredentials(credentials)
    .then(() => {
      dispatch(saveEmployeeCredentialsSuccess());

      dispatch(showErr('Доступы успешно сохранены'));
    })
    .catch((e) => {
      console.warn(e);

      dispatch(saveEmployeeCredentialsFail({
        error: _get(e, ['response', 'data', 'message'], null),
      }));

      dispatch(showErr('Произошла ошибка при сохранении'));
    });
};
