import React, { ReactNode } from 'react';
import cx from 'classnames';
import s from './Page.module.css';

type Props = {
  large?: boolean;
  semiLarge?: boolean;
  children: ReactNode
  headerComponent?: ReactNode
  footerComponent?: ReactNode
};

function Page(props: Props) {
  const {
    headerComponent,
    footerComponent,
    children,
    large,
    semiLarge,
  } = props;

  return (
    <div className={cx(s.container, large && s.largeContainer, semiLarge && s.semiLargeContainer)}>
      {!!headerComponent && headerComponent}
      {children}
      {!!footerComponent && footerComponent}
    </div>
  );
}

export default Page;
