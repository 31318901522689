import { UploadFile } from 'antd/lib/upload/interface';

export interface IBranch {
  id: number;
  email: string;
  phone: string;
  branchName: string;
  userName: string;
  organization: string;
  address: string;
  region: {
    name: string;
  }
}

export interface IBackendBranch extends Omit<IBranch, 'region'> {
  region: string;
}

export interface IEmployee {
  id: number;
  email: string;
  phone: string;
  name: string;
  supplierTypes: {
    id: number;
    name: string;
  }[];
}

export interface IBranchCredentials {
  login: string;
  password: string;
}

export interface IEmployeeCredentials {
  login: string;
  password: string;
}

export enum UserTypes {
  Customer = 'customer',
  Branch = 'branch',
  UndefinedBranch = 'undefinedBranch',
  Provider = 'provider',
  Employee = 'employee',
  UndefinedEmployee = 'undefinedEmployee',
  Undefined = 'undefined',
}

export enum SupplierTypes {
  Equipment = 'Оборудование',
  Materials = 'Материалы',
  Tools = 'Инструменты',
  Goods = 'Хоз. товары',
  Orthodontics = 'Ортодонтия',
  Implantology = 'Имплантология',
  Expendables = 'Расходники',
  Hygiene = 'Гигиена',
}

export const SupplierTypesOptions = [
  {
    label: 'Оборудование',
    value: SupplierTypes.Equipment,
  },
  {
    label: 'Материалы',
    value: SupplierTypes.Materials,
  },
  {
    label: 'Инструменты',
    value: SupplierTypes.Tools,
  },
  {
    label: 'Хоз. товары',
    value: SupplierTypes.Goods,
  },
  {
    label: 'Ортодонтия',
    value: SupplierTypes.Orthodontics,
  },
  {
    label: 'Имплантология',
    value: SupplierTypes.Implantology,
  },
  {
    label: 'Расходники',
    value: SupplierTypes.Expendables,
  },
  {
    label: 'Гигиена',
    value: SupplierTypes.Hygiene,
  },
];

export interface IMarketGoodFile {
  id: number;
  path: string;
}

export interface IMarketGoodAuthor {
  id: number;
  name: string;
  email: string;
  organization: string;
}

export interface IMarketGood {
  author: IMarketGoodAuthor;
  code: string;
  createdDate: string;
  description: string;
  id: number;
  files: IMarketGoodFile[];
  name: string;
  status: GoodStatuses;
  type: {
    name: string;
  };
  updatedDate: string;
  supplierType: {
    name: string;
  };
}

export interface IMarketCategory {
  description: string | null;
  id: number;
  name: string;
  showcaseGoods: IMarketGood[];
}

export type GoodUploadFile = UploadFile<File> & {
  id: number;
  errors?: string[];
};

export type LocationState = undefined | {
  from?: string;
};

export type InvoiceUploadFile = UploadFile<File> & {
  id: number;
  errors?: string[];
};

export type InvoiceGood = {
  id: number;
  name: string;
  amount: number;
  type: string;
  price: number;
};

export type InvoiceGoodMatch = Omit<InvoiceGood, 'type' | 'price'> & {
  code: string | null;
  match?: InvoiceGood;
};

export type InvoiceProvider = {
  organization: string;
  deliveryType?: DeliveryTypes,
  deliveryDate?: string;
  name?: string;
  website?: string;
  email?: string;
  deliveryPrice?: number;
  phone?: string;
};

export type Invoice = {
  providerInfo: InvoiceProvider;
  goods: InvoiceGoodMatch[];
  determineFailed: InvoiceGood[];
};

export enum GoodStatuses {
  MODERATION = 'MODERATION',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export const GoodStatusesLabels = {
  [GoodStatuses.MODERATION]: 'На модерации',
  [GoodStatuses.ACCEPTED]: 'Добавлен',
  [GoodStatuses.DECLINED]: 'Отклонен',
};

export interface MarketGoodsResponse {
  items: IMarketGood[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export enum OAuthServices {
  Google = 'google',
  Yandex = 'yandex',
}

export enum DeliveryTypes {
  Courier = 'Courier',
  TransportCompany = 'TransportCompany',
}

export const DeliveryTypesLabels = {
  [DeliveryTypes.Courier]: 'Курьер',
  [DeliveryTypes.TransportCompany]: 'Транспортная компания',
};

export interface IOffer {
  id: number;
  organization: string;
  column: number;
}

export interface IOfferGood {
  id: number;
  price: string;
  amount: number | null;
  offer: IOffer;
  inCart: boolean;
}

export interface IOrder {
  id: number;
  name: string;
  date: string;
}

export interface IGoodType {
  name: string;
}

export interface IPurchase {
  id: number;
  name: string;
  amount: number;
  order: IOrder;
  type: IGoodType;
  offersGoods: IOfferGood[];
}

export interface PurchaseHistoryResponse {
  history: {
    items: IPurchase[];
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    }
  };
  filterNames?: string[];
}
