import React, { FC, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IMarketGoodFile } from 'src/constants/enums';

import s from '../index.module.css';

interface CarouselProps {
  files: IMarketGoodFile[];
  onClick: (index: number) => void;
}

const Carousel: FC<CarouselProps> = (props) => {
  const {
    files,
    onClick,
  } = props;

  if (files.length === 0) {
    return null;
  }

  const checkForDrag = useRef<number>(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    checkForDrag.current = e.clientX;
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, id: number) => {
    const mouseUp = e.clientX;

    if (
      mouseUp < checkForDrag.current + 5
      && mouseUp > checkForDrag.current - 5
    ) {
      const imageIndex = (files)
        .findIndex((file) => file.id === id);

      onClick(imageIndex);
    }
  };

  return (
    <div className={s.goodImages}>
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        dots
        dotsClass="slick-dots slick-thumb"
        arrows={false}
          // eslint-disable-next-line react/no-unstable-nested-components
        customPaging={(i) => (
          <img
            alt={`Картинка товара ${files[i].id}`}
            src={files[i].path}
          />
        )}
        infinite
      >
        {files.map((file) => (
          <img
            className={s.goodImage}
            key={file.id}
            src={file.path}
            alt={`Картинка товара ${file.id}`}
            onMouseDown={handleMouseDown}
            onMouseUp={(e) => handleMouseUp(e, file.id)}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
