import React, { ErrorInfo, ReactNode } from 'react';

import { debugApi } from 'src/api/api';

class ErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean; }> {
  constructor(props: { children: ReactNode }) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });

    debugApi.log('hard', {
      type: 'React',
      stack: info.componentStack,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <h1>Произошла ошибка, попробуйте позже.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
