import jwt_decode from 'jwt-decode';

interface IDecodedToken {
  is_provider: boolean;
  sub: number;
  email: string;
  type: string;
  email_verified: boolean;
  iat: number;
  exp: number;
  iss: string;
  is_admin: boolean;
}

const useDecodedToken = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return {
      is_admin: false,
    } as Partial<IDecodedToken>;
  }

  return jwt_decode(token) as IDecodedToken;
};

export default useDecodedToken;
