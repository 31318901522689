import React, { FC } from 'react';

import { IMarketGoodFile } from 'src/constants/enums';

import s from './index.module.css';

interface ImagePreviewProps {
  file: IMarketGoodFile;
}

const ImagePreview: FC<ImagePreviewProps> = (props) => {
  const { file } = props;

  return (
    <div className={s.image}>
      <div className={s.imageSource}>
        <img
          src={file.path}
          className={s.imageContent}
          alt="Загруженное изображение"
        />
      </div>
    </div>
  );
};

export default ImagePreview;
