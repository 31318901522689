import { IEmployee } from 'src/constants/enums';

export enum ActionsTypes {
  DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
  DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST',
  DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL',

  SAVE_EMPLOYEE_CREDENTIALS = 'SAVE_EMPLOYEE_CREDENTIALS',
  SAVE_EMPLOYEE_CREDENTIALS_REQUEST = 'SAVE_EMPLOYEE_CREDENTIALS_REQUEST',
  SAVE_EMPLOYEE_CREDENTIALS_SUCCESS = 'SAVE_EMPLOYEE_CREDENTIALS_SUCCESS',
  SAVE_EMPLOYEE_CREDENTIALS_FAIL = 'SAVE_EMPLOYEE_CREDENTIALS_FAIL',
}

export interface EmployeesState {
  deletingEmployeeId: number | null;
  isCredentialsSaving: boolean;
  savingError: string | null;
}

export interface DeleteEmployee {
  type: ActionsTypes.DELETE_EMPLOYEE,
}

export interface DeleteEmployeeRequest {
  type: ActionsTypes.DELETE_EMPLOYEE_REQUEST,
  payload: Pick<IEmployee, 'id'>
}

export interface DeleteEmployeeSuccess {
  type: ActionsTypes.DELETE_EMPLOYEE_SUCCESS,
}

export interface DeleteEmployeeFail {
  type: ActionsTypes.DELETE_EMPLOYEE_FAIL,
}

export interface SaveEmployeeCredentials {
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS,
}

export interface SaveEmployeeCredentialsRequest {
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_REQUEST,
}

export interface SaveEmployeeCredentialsSuccess {
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_SUCCESS,
}

export interface SaveEmployeeCredentialsFail {
  type: ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_FAIL,
  payload: {
    error: string;
  }
}

export type EmployeesActions = (
  DeleteEmployee
  | DeleteEmployeeRequest
  | DeleteEmployeeSuccess
  | DeleteEmployeeFail
  | SaveEmployeeCredentials
  | SaveEmployeeCredentialsRequest
  | SaveEmployeeCredentialsSuccess
  | SaveEmployeeCredentialsFail
);
