import React, { FC, useState } from 'react';
import cx from 'classnames';
import DownOutlined from '@ant-design/icons/DownOutlined';
import { Button } from 'antd';
import MinusCircleOutlined from '@ant-design/icons/MinusCircleOutlined';

import s from './styles.module.css';

interface IAccordion {
  title: string;
  openByDefault?: boolean;
  onRemove?: () => void;
}

const Accordion: FC<IAccordion> = (props) => {
  const {
    title,
    openByDefault = false,
    onRemove,
    children,
  } = props;

  const [isOpened, setIsOpened] = useState(openByDefault);

  return (
    <div>
      <div
        className={s.header}
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className={s.title}>
          {title}
          <div className={cx(s.icon, isOpened && s.iconRotated)}>
            <DownOutlined />
          </div>
        </div>
        {onRemove && (
          <Button
            shape="circle"
            icon={<MinusCircleOutlined />}
            onClick={onRemove}
          />
        )}
      </div>
      {isOpened && children}
    </div>
  );
};

export default Accordion;
