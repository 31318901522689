import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usersApi } from 'src/api/api';

import { IMarketCategory } from 'src/constants/enums';

const useMarket = () => {
  const [categories, setCategories] = useState<IMarketCategory[] | null>(null);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setStatus('loading');
    setError(null);

    usersApi.getMarket({
      limit: 21,
      page: 1,
    })
      .then((e) => {
        setCategories(e.data);
      })
      .catch((e) => {
        console.warn(e);

        setError(e);
      })
      .finally(() => {
        setStatus('loaded');
      });
  }, [location]);

  return {
    categories,
    status,
    error,
  };
};

export default useMarket;
