import { useCallback, useEffect, useState } from 'react';
import { TablePaginationConfig, message } from 'antd';
import { debounce } from 'lodash';
import { FilterValue } from 'antd/lib/table/interface';

import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { PurchaseHistoryResponse } from 'src/constants/enums';

import { usersApi } from 'src/api/api';

const usePurchaseTable = () => {
  const [purchaseHistory, setPurchaseHistory] = useState<PurchaseHistoryResponse>({
    history: {
      items: [],
      meta: {
        totalItems: 0,
        itemCount: 0,
        itemsPerPage: 0,
        totalPages: 0,
        currentPage: 0,
      },
    },
  });

  const [status, setStatus] = useState('idle');

  const [tableFilter, setTableFilter] = useState<FilterValue | null>(null);

  const [query, setQuery] = useQueryParams({
    filter: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
  });

  const {
    filter,
    page,
    limit,
  } = query;

  const loadHistory = async () => {
    setStatus('loading');

    try {
      const { data } = await usersApi.getPurchaseHistory({
        limit,
        page,
        filter,
      });

      setTableFilter(null);
      setPurchaseHistory(data);
    } catch (e) {
      message.error('Произошла ошибка при получении истории заказов');
    } finally {
      setStatus('loaded');
    }
  };

  useEffect(() => {
    if (filter) {
      loadHistory();
    }
  }, [filter, limit, page]);

  const onPageChange = (newPage: number, newLimit?: number) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.limit !== newLimit ? 1 : newPage,
      limit: newLimit,
    }));
  };

  const onPageSizeChange = () => {};

  const onFilterChange = useCallback(debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: 1,
      filter: event.target.value,
    }));
  }, 1000), []);

  const onTableChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    setTableFilter(filters.name);

    setQuery({
      ...query,
      filter: filters.name?.toString(),
    });
  };

  return {
    purchaseHistory,
    tableFilter,
    filter,
    page,
    limit,
    status,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onTableChange,
  };
};

export default usePurchaseTable;
