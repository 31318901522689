import React, { FC } from 'react';
import ImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { IMarketGoodFile } from 'src/constants/enums';

interface LightboxProps {
  activeImageIndex: number;
  files: IMarketGoodFile[];
  onChange: (index: number) => void;
  onClose: () => void;
}

const Lightbox: FC<LightboxProps> = (props) => {
  const {
    files,
    activeImageIndex,
    onClose,
    onChange,
  } = props;

  if (files.length === 0) {
    return null;
  }

  return (
    <ImageLightbox
      mainSrc={files[activeImageIndex].path}
      nextSrc={files[(activeImageIndex + 1) % files.length].path}
      prevSrc={files[(activeImageIndex + files.length - 1) % files.length].path}
      onCloseRequest={onClose}
      onMovePrevRequest={() => onChange((activeImageIndex + files.length - 1) % files.length)}
      onMoveNextRequest={() => onChange((activeImageIndex + 1) % files.length)}
      enableZoom={false}
    />
  );
};

export default Lightbox;
