import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import MarketCategories from 'src/components/MarketCategories';
import MarketCategory from 'src/components/MarketCategory';

const MarketMain = () => {
  const [query] = useQueryParams({
    sort: StringParam,
    supplierType: StringParam,
    search: StringParam,
  });

  const { supplierType, search } = query;

  return (
    <>
      {(!supplierType && !search) && (
        <MarketCategories />
      )}
      {(supplierType || search) && (
        <MarketCategory />
      )}
    </>
  );
};

export default MarketMain;
