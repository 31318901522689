import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'src/routes';

import s from './styles.module.css';

const ProposalNotActive = () => (
  <div className={s.notfound_container}>
    <div className={s.notfound}>
      <h2>Заявка закрыта</h2>
      <p>Заявка закрыта и недоступна для редактирования</p>
      <Link to={routes.index}>Вернуться на главную</Link>
    </div>
  </div>
);

export default ProposalNotActive;
