const routes = {
  index: '/',
  about: '/about',
  contacts: '/contacts',
  confirmEmail: '/actions/confirm-email',
  forgetPassword: '/actions/forget-password',
  agreement: '/agreement',
  privacyPolicy: '/politika-obrabotki-personalnyh-dannyh',
  personal: '/personal',
  settings: '/settings',
  success: '/success',
  order: '/order/:id',
  orderCompare: '/order/compare/:id',
  new: '/new',
  newTemplate: '/new-template/:id',
  userAgreement: '/soglashenie',
  logout: '/logout',
  branches: {
    index: '/branches',
    add: '/branches/add',
    edit: '/branches/:id/edit',
  },
  employees: {
    index: '/employees',
    add: '/employees/add',
    edit: '/employees/:id/edit',
  },
  termsOfUse: '/terms-of-use',
  personalMarket: {
    index: '/my-market',
    add: '/my-market/add',
    edit: '/my-market/:id/edit',
  },
  market: '/market',
  marketGood: '/market/:id',
  marketSearch: '/market-search',
  marketModeration: '/market-moderation',
  externalAuth: '/oauth/:service',
  purchaseHistory: '/purchase-history',
};

export default routes;
