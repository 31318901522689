/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

import {
  IBranch, IBranchCredentials,
} from 'src/constants/enums';

import {
  BranchesActions,
  ActionsTypes,
  DeleteBranchRequest,
  DeleteBranchSuccess,
  DeleteBranchFail,
  SaveBranchCredentialsRequest,
  SaveBranchCredentialsSuccess,
  SaveBranchCredentialsFail,
} from 'src/types/branches';

import { AppThunk } from '..';

import { usersApi } from '../../api/api';
import { showErr } from '../action-creators/popUp';

const deleteBranchRequest = (data: Pick<IBranch, 'id'>): DeleteBranchRequest => ({
  type: ActionsTypes.DELETE_BRANCH_REQUEST,
  payload: data,
});

const deleteBranchSuccess = (): DeleteBranchSuccess => ({
  type: ActionsTypes.DELETE_BRANCH_SUCCESS,
});

const deleteBranchFail = (): DeleteBranchFail => ({
  type: ActionsTypes.DELETE_BRANCH_FAIL,
});

export const deleteBranch = (
  data: Pick<IBranch, 'id'>,
): AppThunk<BranchesActions> => (dispatch) => {
  dispatch(deleteBranchRequest(data));

  usersApi.deleteBranch(data)
    .then(() => {
      dispatch(deleteBranchSuccess());

      dispatch(showErr('Филиал удален успешно!'));
    })
    .catch((e) => {
      console.warn(e);

      dispatch(deleteBranchFail());

      dispatch(showErr('Произошла ошибка при удалении'));
    });
};

const saveBranchCredentialsRequest = (): SaveBranchCredentialsRequest => ({
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS_REQUEST,
});

const saveBranchCredentialsSuccess = (): SaveBranchCredentialsSuccess => ({
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS_SUCCESS,
});

const saveBranchCredentialsFail = (payload: { error: string }): SaveBranchCredentialsFail => ({
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS_FAIL,
  payload,
});

export const saveBranchCredentials = (
  credentials: IBranchCredentials,
): AppThunk<BranchesActions> => (dispatch) => {
  dispatch(saveBranchCredentialsRequest());

  usersApi.saveBranchCredentials(credentials)
    .then(() => {
      dispatch(saveBranchCredentialsSuccess());

      dispatch(showErr('Доступы успешно сохранены'));
    })
    .catch((e) => {
      console.warn(e);

      dispatch(saveBranchCredentialsFail({
        error: _get(e, ['response', 'data', 'message'], null),
      }));

      dispatch(showErr('Произошла ошибка при сохранении'));
    });
};
