import { useState } from 'react';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import { usersApi } from 'src/api/api';

import { Invoice, InvoiceUploadFile } from 'src/constants/enums';

const ValidationErrorTypes = {
  ContentType: 'ContentType',
  Size: 'Size',
};

export const ValidationErrorTexts = {
  [ValidationErrorTypes.ContentType]: 'Загружаемый файл имеет неверный тип',
  [ValidationErrorTypes.Size]: 'Загружаемый файл не может быть больше размера 5МБ',
};

const checkIsValidFile = async (uploadedFile: RcFile) => {
  const isCorrectContentType = ['application/pdf']
    .includes(uploadedFile.type);

  if (!isCorrectContentType) {
    return [ValidationErrorTypes.ContentType];
  }

  if (uploadedFile.size >= 50 * 1024 * 1024) {
    return [ValidationErrorTypes.Size];
  }

  return [];
};

const useUploadInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState<InvoiceUploadFile[]>([]);
  const [uploadedInvoice, setUploadedInvoice] = useState<Invoice | null>(null);

  const { id } = useParams<{ id: string }>();

  const onBeforeUpload = () => false;

  const onUpload = async (data: UploadChangeParam<UploadFile<File>> & { valid?: boolean }) => {
    const { fileList } = data;

    // TODO: переделать
    const {
      validFiles: newValidFiles,
      invalidFiles: newInvalidFiles,
    } = await (fileList as InvoiceUploadFile[])
      .reduce(async (memo, newFile) => {
        const resolvedMemo = await memo;
        const errors = await checkIsValidFile(newFile.originFileObj as RcFile);

        if (errors.length > 0) {
          // eslint-disable-next-line no-param-reassign
          newFile.errors = errors;

          return {
            ...resolvedMemo,
            invalidFiles: [...resolvedMemo.invalidFiles, newFile],
          };
        }

        // eslint-disable-next-line no-param-reassign
        newFile.id = Date.now();

        return {
          ...resolvedMemo,
          validFiles: [...resolvedMemo.validFiles, newFile],
        };
      }, Promise.resolve({
        validFiles: [],
        invalidFiles: [],
      } as {
        validFiles: InvoiceUploadFile[],
        invalidFiles: InvoiceUploadFile[],
      }));

    if (newValidFiles.length > 0) {
      try {
        setIsLoading(true);

        const response = await usersApi.uploadProviderInvoice({
          id: Number(id),
          file: newValidFiles[0].originFileObj as File,
        });

        setUploadedInvoice(response.data);
      } catch (e) {
        console.warn(e);

        message.error('Произошла ошибка при загрузке счета');
      } finally {
        setIsLoading(false);
      }
    }

    if (newInvalidFiles.length > 0) {
      setInvalidFiles(newInvalidFiles);
    }
  };

  return {
    isLoading,
    uploadedInvoice,
    setUploadedInvoice,
    invalidFiles,
    setInvalidFiles,
    onBeforeUpload,
    onUpload,
  };
};

export default useUploadInvoice;
