import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import s from './index.module.css';

interface MarketBreadcrumbsProps {
  items: Array<{
    title?: string;
    url: string;
  }>;
}

const MarketBreadcrumbs: FC<MarketBreadcrumbsProps> = (props) => {
  const { items } = props;

  if (!items.length) {
    return null;
  }

  return (
    <Breadcrumb className={s.breadcrumbs} separator=">">
      {items
        .filter(({ title }) => title)
        .map(({ title, url }) => (
          <Breadcrumb.Item className={s.breadcrumb}>
            <NavLink className={s.breadcrumbLink} to={url}>
              {title}
            </NavLink>
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
};

export default MarketBreadcrumbs;
