import React, { FC, useRef, useState } from 'react';

import {
  Input,
  Tag,
  message,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { usersApi } from 'src/api/api';

interface CustomProvidersProps {
  customProviderEmails: string[];
  setCustomProviderEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const checkProviderExists = (fn: () => Promise<void>) => async () => {
  try {
    await fn();
  } catch (e) {
    message.error('Поставщик с такой почтой уже зарегистрирован');
  }
};

const CustomProviders: FC<CustomProvidersProps> = (props) => {
  const {
    customProviderEmails,
    setCustomProviderEmails,
  } = props;

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const inputRef = useRef<Input | null>(null);
  const editInputRef = useRef<Input | null>(null);

  const handleClose = (removedTag: string) => {
    const newTags = customProviderEmails.filter((tag) => tag !== removedTag);
    setCustomProviderEmails(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleInputConfirm = checkProviderExists(async () => {
    if (inputValue && customProviderEmails.indexOf(inputValue) === -1) {
      await usersApi.checkProviderExists({ email: inputValue });

      setCustomProviderEmails([...customProviderEmails, inputValue]);
    }

    setInputVisible(false);
    setInputValue('');
  });

  const handleEditInputConfirm = checkProviderExists(async () => {
    await usersApi.checkProviderExists({ email: editInputValue });

    const newTags = [...customProviderEmails];
    newTags[editInputIndex] = editInputValue;

    setCustomProviderEmails(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  });

  return (
    <>
      {customProviderEmails.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        return (
          <Tag
            key={tag}
            closable
            onClose={() => handleClose(tag)}
          >
            <span
              onDoubleClick={(e) => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
            >
              {tag}
            </span>
          </Tag>
        );
      })}
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined />
          {' '}
          Добавить поставщика
        </Tag>
      )}
    </>
  );
};

export default CustomProviders;
