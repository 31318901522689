const applyQuery = (url: string) => {
  const { search } = window.location;

  if (search === '?') {
    return url;
  }

  return url + search;
};

export default applyQuery;
