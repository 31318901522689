import React, { FC, MouseEvent, ReactElement } from 'react';
import { Form, Input } from 'antd';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { HistoryOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import routes from 'src/routes';

import useMarketHeader from '../useMarketHeader';

import s from './index.module.css';

interface MarketHintProps {
  hint: string;
  icon: ReactElement;
  onClick: (hint: string) => void;
  onDelete?: (hint: string) => void;
}

const MarketHint: FC<MarketHintProps> = (props) => {
  const {
    hint,
    icon,
    onClick,
    onDelete,
  } = props;

  const handleClick = () => {
    onClick(hint);
  };

  const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (onDelete) {
      onDelete(hint);
    }
  };

  return (
    <li
      className={s.searchHint}
      onClick={handleClick}
    >
      <div className={s.searchHintIcon}>
        {icon}
      </div>
      {hint}
      {onDelete && (
        <button
          className={s.searchHintDeleteButton}
          type="button"
          onClick={handleDeleteClick}
        >
          Удалить
        </button>
      )}
    </li>
  );
};

const MarketHeaderForm = () => {
  const {
    status,
    search,
    setSearch,
    isFilterEditing,
    setIsFilterEditing,
    tips: {
      hints,
      history,
    },
    tipsMenuRef,
    onChange,
    onDelete,
  } = useMarketHeader();

  const [form] = Form.useForm();

  const browserHistory = useHistory();

  const handleFinish = () => {
    setIsFilterEditing(false);

    browserHistory.push(`${routes.market}?search=${search}`);
  };

  const handleClick = (item: string) => {
    setIsFilterEditing(false);

    setSearch(item);

    form.submit();
  };

  const hasHints = hints.length > 0 || history.length > 0;

  return (
    <Form
      className={s.searchForm}
      form={form}
      onFinish={handleFinish}
    >
      <div className={s.searchInputContainer}>
        <Input
          className={cx(s.searchInput, isFilterEditing && hasHints && s.searchInputWithHints)}
          placeholder="Наименование, артикул"
          prefix={<SearchOutlined />}
          value={search}
          onChange={onChange}
        />
        {status === 'loading' && (
          <span className={s.searchInputLoader}>
            <LoadingOutlined style={{ fontSize: 16 }} />
          </span>
        )}
      </div>
      {isFilterEditing && hasHints && (
        <div className={s.searchHintsContainer} ref={tipsMenuRef}>
          <ul className={s.searchHintsList}>
            {history.map((item) => (
              <MarketHint
                key={item}
                hint={item}
                icon={<HistoryOutlined />}
                onClick={handleClick}
                onDelete={onDelete}
              />
            ))}
          </ul>
          <ul className={s.searchHintsList}>
            {hints.map((item) => (
              <MarketHint
                key={item}
                hint={item}
                icon={<SearchOutlined />}
                onClick={handleClick}
              />
            ))}
          </ul>
        </div>
      )}
    </Form>
  );
};

export default MarketHeaderForm;
