import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';

import { usersApi } from 'src/api/api';

interface IMarketCategory {
  description: string | null;
  id: number;
  items: Array<any>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  name: string;
}

const useMarketGoods = () => {
  const [category, setCategory] = useState<IMarketCategory | null>(null);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  const location = useLocation();

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    supplierType: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
  });

  const {
    search,
    supplierType,
    page,
    limit,
  } = query;

  useEffect(() => {
    setCategory(null);
    setStatus('loading');
    setError(null);

    usersApi.getMarket({
      limit,
      page,
      supplierType,
      filter: search,
    })
      .then((e) => {
        setCategory(e.data);
      })
      .catch((e) => {
        console.warn(e);

        setError(e);
      })
      .finally(() => {
        setStatus('loaded');
      });
  }, [location, limit, page, supplierType, search]);

  const onPageChange = (newPage: number, newLimit?: number) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.limit !== newLimit ? 1 : newPage,
      limit: newLimit,
    }));
  };

  const onPageSizeChange = () => {};

  return {
    category,
    search,
    page,
    limit,
    status,
    error,
    onPageChange,
    onPageSizeChange,
  };
};

export default useMarketGoods;
