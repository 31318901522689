import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { debounce } from 'lodash';

import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { usersApi } from 'src/api/api';

import { MarketGoodsResponse } from 'src/constants/enums';
import { message } from 'antd';

const useGoodsTable = () => {
  const [goods, setGoods] = useState<MarketGoodsResponse | null>(null);
  const [status, setStatus] = useState('idle');
  const [deletingGoodId, setDeletingGoodId] = useState<number | null>(null);

  const [query, setQuery] = useQueryParams({
    filter: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
  });

  const {
    filter,
    page,
    limit,
  } = query;

  const loadGoods = () => {
    setStatus('loading');

    usersApi.getProviderMarket({
      limit,
      page,
      filter,
    })
      .then((e) => {
        setGoods(e.data);
      })
      .catch((e) => {
        console.warn(e);

        message.error('Произошла ошибка при получении товаров');
      })
      .finally(() => {
        setStatus('loaded');
      });
  };

  useEffect(() => {
    loadGoods();
  }, [limit, page, filter]);

  const onPageChange = (newPage: number, newLimit?: number) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.limit !== newLimit ? 1 : newPage,
      limit: newLimit,
    }));
  };

  const onPageSizeChange = () => {};

  const onFilterChange = useCallback(debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: 1,
      filter: event.target.value,
    }));
  }, 300), []);

  const onDelete = async (id: number) => {
    const answer = window.confirm('Вы уверены, что хотите удалить этот товар?');

    if (answer) {
      try {
        setDeletingGoodId(id);

        await usersApi.deleteMarketGood({ id });

        loadGoods();
      } catch (e) {
        console.warn(e);

        message.error('Произошла ошибка при удалении товара');
      } finally {
        setDeletingGoodId(null);
      }
    }
  };

  return {
    goods,
    filter,
    page,
    limit,
    status,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    deletingGoodId,
    onDelete,
  };
};

export default useGoodsTable;
