import {
  ActionsTypes,
  BranchActions,
  BranchState,
} from 'src/types/branch';

const initialState: BranchState = {
  isSaving: false,
};

const branchReducer = (
  state = initialState,
  action: BranchActions,
): BranchState => {
  switch (action.type) {
    case ActionsTypes.SAVE_BRANCH_REQUEST: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case ActionsTypes.SAVE_BRANCH_SUCCESS:
    case ActionsTypes.SAVE_BRANCH_FAIL: {
      return {
        ...state,
        isSaving: false,
      };
    }

    default:
      return state;
  }
};

export default branchReducer;
