import React from 'react';
import { ConfigProvider } from 'antd';

import PurchaseTable from './PurchaseTable';

import s from './index.module.css';

const PurchaseHistory = () => (
  <ConfigProvider>
    <div className={s.page}>
      <div className={s.pageBody}>
        <div className={s.pageHeader}>
          <h1 className={s.pageTitle}>История покупок</h1>
        </div>
        <PurchaseTable />
      </div>
    </div>
  </ConfigProvider>
);

export default PurchaseHistory;
