/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
import React, { FC } from 'react';
import {
  Button, Form, Input, Select, Upload,
} from 'antd';
import cx from 'classnames';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

import { IMarketGoodFile, SupplierTypesOptions } from 'src/constants/enums';

import s from './index.module.css';
import ImagePreview from './ImagePreview';

interface MarketGoodFormProps {
  files: IMarketGoodFile[];
  onBeforeUpload: (file: RcFile) => void;
  onUpload: ({ file }: UploadChangeParam<UploadFile<File>>) => void;
  onRemove: (id: number) => void;
}

const MarketGoodForm: FC<MarketGoodFormProps> = (props) => {
  const {
    files,
    onBeforeUpload,
    onUpload,
    onRemove,
  } = props;

  return (
    <div className={s.form}>
      <div className={s.inputGroups}>
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription}>Тип товара</span>
          <Form.Item
            className={s.formItem}
            name={['supplierType', 'name']}
            rules={[{
              required: true,
              message: 'Выберите тип товара',
            }]}
          >
            <Select
              placeholder="Выберите тип товара"
            >
              {SupplierTypesOptions.map(({ label, value }) => (
                <Select.Option
                  key={value}
                  value={value}
                >
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription}>Наименование товара</span>
          <Form.Item
            className={s.formItem}
            name="name"
            rules={[{
              required: true,
              message: 'Заполните наименование товара',
            }]}
          >
            <Input
              placeholder="Введите наименование товара"
              maxLength={256}
            />
          </Form.Item>
        </div>
        <div className={s.inputGroupItem}>
          <div className={s.inputGroupFlex}>
            <span className={s.inputDescription}>Артикул</span>
            <Form.Item
              className={s.formItem}
              name="code"
            >
              <Input
                placeholder="Артикул 0-20 символов"
                maxLength={20}
              />
            </Form.Item>
          </div>
          <div className={s.inputGroupFlex}>
            <span className={s.inputDescription}>Единица измерения</span>
            <Form.Item
              className={s.formItem}
              name={['type', 'name']}
              rules={[{
                required: true,
                message: 'Заполните единицу измерения',
              }]}
            >
              <Select placeholder="Штук / упаковок">
                <Select.Option value="штука">Штука</Select.Option>
                <Select.Option value="упаковка">Упаковка</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription}>Изображения (до 4-х штук)</span>
          {files.length < 4 && (
            <Upload
              action=""
              className={cx(s.formItem, s.formItemUpload)}
              accept="image/png, image/jpg, image/jpeg"
              beforeUpload={onBeforeUpload}
              onChange={onUpload}
              multiple
              // TODO: antd багует тут https://github.com/ant-design/ant-design/issues/8036
              fileList={[]}
            >
              <Button icon={<UploadOutlined />}>
                Добавить изображения
              </Button>
            </Upload>
          )}
          {/* // TODO: переделать */}
          <ul className={s.imagesList}>
            {files.map((file) => (
              <li className={s.image}>
                <ImagePreview file={file} />
                <button
                  className={s.imageRemove}
                  type="button"
                  onClick={() => onRemove(file.id)}
                >
                  <DeleteOutlined style={{ fontSize: 16, color: '#fff' }} />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className={s.inputGroupItem}>
          <span className={s.inputDescription}>Описание товара</span>
          <Form.Item
            className={cx(s.formItem, s.formItemTextarea)}
            name="description"
            rules={[{
              max: 20000,
              message: 'Описание товара не может быть больше 20 000 символов',
            }]}
          >
            <Input.TextArea
              placeholder="Введите описание товара не более 20 000 символов"
              maxLength={20000}
            />
          </Form.Item>
        </div>
      </div>
      {/* <div className={cx(s.image, s.imageDesktop)}>
        <ImagePreview file={file} />
      </div> */}
    </div>
  );
};

export default MarketGoodForm;
