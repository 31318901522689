import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import routes from 'src/routes';

import MarketGoodStyles from '../index.module.css';

import s from './index.module.css';

interface AddMarketGoodToOrderButtonProps {
  onClick: () => void;
}

const AddMarketGoodToOrderButton: FC<AddMarketGoodToOrderButtonProps> = (props) => {
  const { onClick } = props;

  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    onClick();

    setIsOpened(true);
  };

  return (
    <>
      <button
        className={cx(MarketGoodStyles.goodControl, MarketGoodStyles.addGoodButton)}
        type="button"
        onClick={handleClick}
      >
        Добавить к заказу
      </button>
      <Modal
        bodyStyle={{ padding: '49px 70px 39px' }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
        visible={isOpened}
        footer={null}
        width="571px"
        onCancel={() => setIsOpened(false)}
      >
        <h2 className={s.modalTitle}>
          Товар успешно добавлен в заказ
        </h2>
        <div className={s.modalControls}>
          <button
            className={cx(s.modalControl)}
            type="button"
            onClick={() => setIsOpened(false)}
          >
            Смотреть дальше витрину
          </button>
          <NavLink
            className={cx(s.modalControl, s.goToOrderLink)}
            to={localStorage.getItem('DENTMP_SHOWCASE_ORDER_SOURCE') || routes.new}
          >
            Перейти к заказу
          </NavLink>
        </div>
      </Modal>
    </>
  );
};

export default AddMarketGoodToOrderButton;
