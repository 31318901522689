import {
  useEffect,
  useState,
} from 'react';

import {
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { message } from 'antd';

import { usersApi } from 'src/api/api';

import { GoodStatuses, MarketGoodsResponse } from 'src/constants/enums';

const useGoodsTable = () => {
  const [goods, setGoods] = useState<MarketGoodsResponse | null>(null);
  const [status, setStatus] = useState('idle');

  const [acceptingGoodId, setAcceptingGoodId] = useState<number | null>(null);
  const [decliningGoodId, setDecliningGoodId] = useState<number | null>(null);

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
  });

  const {
    page,
    limit,
  } = query;

  const loadGoods = async () => {
    setStatus('loading');

    try {
      const { data } = await usersApi.getModeratedMarket({
        limit,
        page,
      });

      setGoods(data);
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при получении товаров');
    } finally {
      setStatus('loaded');
    }
  };

  useEffect(() => {
    loadGoods();
  }, [limit, page]);

  const onPageChange = (newPage: number, newLimit?: number) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.limit !== newLimit ? 1 : newPage,
      limit: newLimit,
    }));
  };

  const onPageSizeChange = () => {};

  const onAccept = async (id: number) => {
    setAcceptingGoodId(id);

    try {
      await usersApi.moderateMarketGood({
        id,
        status: GoodStatuses.ACCEPTED,
      });

      loadGoods();
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при модерации товара');
    } finally {
      setAcceptingGoodId(null);
    }
  };

  const onDecline = async (id: number) => {
    setDecliningGoodId(id);

    try {
      await usersApi.moderateMarketGood({
        id,
        status: GoodStatuses.DECLINED,
      });

      loadGoods();
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при модерации товара');
    } finally {
      setDecliningGoodId(null);
    }
  };

  return {
    goods,
    page,
    limit,
    status,
    onPageChange,
    onPageSizeChange,
    acceptingGoodId,
    onAccept,
    decliningGoodId,
    onDecline,
  };
};

export default useGoodsTable;
