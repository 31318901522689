/* eslint-disable import/prefer-default-export */
import { IEmployee } from 'src/constants/enums';
import history from 'src/helpers/history';
import routes from 'src/routes';

import {
  EmployeeActions,
  ActionsTypes,
  SaveEmployeeRequest,
  SaveEmployeeSuccess,
  SaveEmployeeFail,
} from 'src/types/employee';

import { AppThunk } from '..';

import { usersApi } from '../../api/api';
import { showErr } from '../action-creators/popUp';

const makeRequest = (employee: IEmployee) => {
  if (employee.id) {
    return usersApi.updateEmployee(employee);
  }

  const {
    id,
    ...other
  } = employee;

  return usersApi.createEmployee(other);
};

const saveEmployeeRequest = (): SaveEmployeeRequest => ({
  type: ActionsTypes.SAVE_EMPLOYEE_REQUEST,
});

const saveEmployeeSuccess = (): SaveEmployeeSuccess => ({
  type: ActionsTypes.SAVE_EMPLOYEE_SUCCESS,
});

const saveEmployeeFail = (): SaveEmployeeFail => ({
  type: ActionsTypes.SAVE_EMPLOYEE_FAIL,
});

export const saveEmployee = (
  employee: IEmployee,
): AppThunk<EmployeeActions> => (dispatch) => {
  dispatch(saveEmployeeRequest());

  makeRequest(employee)
    .then((response) => {
      if (!response.data.result) {
        throw response;
      }

      dispatch(saveEmployeeSuccess());

      dispatch(showErr('Настройки сохранены успешно!'));

      history.push(routes.employees.index);
    })
    .catch((e) => {
      console.warn(e);

      dispatch(saveEmployeeFail());

      dispatch(showErr('Произошла ошибка при сохранении!'));
    });
};
