export enum ActionsTypes {
  SAVE_BRANCH = 'SAVE_BRANCH',
  SAVE_BRANCH_REQUEST = 'SAVE_BRANCH_REQUEST',
  SAVE_BRANCH_SUCCESS = 'SAVE_BRANCH_SUCCESS',
  SAVE_BRANCH_FAIL = 'SAVE_BRANCH_FAIL',
}

export interface BranchState {
  isSaving: boolean;
}

export interface SaveBranch {
  type: ActionsTypes.SAVE_BRANCH,
}

export interface SaveBranchRequest {
  type: ActionsTypes.SAVE_BRANCH_REQUEST,
}

export interface SaveBranchSuccess {
  type: ActionsTypes.SAVE_BRANCH_SUCCESS,
}

export interface SaveBranchFail {
  type: ActionsTypes.SAVE_BRANCH_FAIL,
}

export type BranchActions = (
  SaveBranch
  | SaveBranchRequest
  | SaveBranchSuccess
  | SaveBranchFail
);
