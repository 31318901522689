import { RcFile } from 'antd/lib/upload/interface';

import getBase64 from './getBase64';

const getFileResolution = async (file: RcFile): Promise<{ width: number; height: number }> => {
  const url = await getBase64(file);

  return new Promise((resolve) => {
    const image = document.createElement('img');
    image.src = url;

    image.addEventListener('load', () => {
      const { width, height } = image;

      resolve({ width, height });
    });
  });
};

export default getFileResolution;
