import React from 'react';

import s from './About.module.css';

const About = () => (
  <div className={s.about}>
    <div className={s.about_how}>
      <div className={s.about_title}>Как работает сервис?</div>
      <div className={s.right_block_item}>
        <span className={s.item_num}>1.</span>
        <div className={s.item_text}>Оставьте заявку на нашем сайте</div>
      </div>
      <div className={s.right_block_item}>
        <span className={s.item_num}>2.</span>
        <div className={s.item_text}>Соберите лучшие предложения от наших поставщиков</div>
      </div>
      <div className={s.right_block_item}>
        <span className={s.item_num}>3.</span>
        <div className={s.item_text}>Сравните все предложения</div>
      </div>
      <div className={s.right_block_item}>
        <span className={s.item_num}>4.</span>
        <div className={s.item_text}>Выбирайте наиболее выгодное предложение</div>
      </div>
      <div className={s.right_block_item}>
        <span className={s.item_num}>5.</span>
        <div className={s.item_text}>Оформите заявку и получите товар</div>
      </div>
    </div>
    <div className={s.about_contacts}>
      <div className={s.about_title}>
        У Вас остались вопросы или необходима помощь?
      </div>
      <div className={s.about_contacts_block}>
        <div className={s.right_block_item}>
          <span className={s.item_text}>
            Звоните по номеру:
            {' '}
            <a href="tel:+79110067993">+7 (911) 006-79-93</a>
          </span>
        </div>
      </div>
      <div className={s.about_contacts_block}>
        <span className={s.item_text}>
          Режим работы:
          с 9:00 до 21:00
        </span>
      </div>
    </div>
  </div>
);

export default About;
