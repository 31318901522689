import React, { useMemo } from 'react';
import { Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import MarketStyles from 'src/pages/Market/index.module.css';

import routes from 'src/routes';

import useMarketGoods from './useMarketGoods';
import MarketGoodsList from '../MarketGoodsList';
import MarketBreadcrumbs from '../MarketBreadcrumbs';

import s from './index.module.css';

const MarketCategory = () => {
  const {
    category,
    search,
    page,
    limit,
    onPageChange,
    onPageSizeChange,
  } = useMarketGoods();

  const breadcrumbs = useMemo(() => (
    [
      {
        title: 'DentMarketPlace',
        url: routes.index,
      },
      {
        title: search
          ? 'Результаты поиска'
          : category?.name,
        url: search
          ? window.location.pathname + window.location.search
          : `${routes.market}?supplierType=${category?.name}`,
      },
    ]
  ), [category, search]);

  return (
    <>
      <div className={s.breadcrumbsContainer}>
        <MarketBreadcrumbs items={breadcrumbs} />
      </div>
      <h1 className={MarketStyles.pageContentTitle}>
        {search ? `Результаты поиска: ${search}` : category?.name}
      </h1>
      {!category && (
        <div className={MarketStyles.pageContentLoader}>
          <LoadingOutlined style={{ fontSize: 48 }} />
        </div>
      )}
      {category && (
        <>
          <p className={MarketStyles.pageContentDescription}>
            {category.description}
          </p>
          {category.items.length === 0 && (
            <p className={s.emptyGoods}>
              По вашему запросу ничего не найдено
            </p>
          )}
          <div className={s.goods}>
            <MarketGoodsList items={category.items} />
          </div>
          {category.meta.totalItems > 10 && (
            <Pagination
              current={page}
              pageSize={limit}
              showSizeChanger
              pageSizeOptions={['10', '20', '50']}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
              total={category.meta.totalItems}
            />
          )}
        </>
      )}
    </>
  );
};

export default MarketCategory;
