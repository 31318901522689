import React, {
  FC,
  useEffect, useMemo, useState,
} from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import {
  message, Table, TablePaginationConfig, Tooltip,
} from 'antd';
import Button from 'antd/lib/button';
import { useParams } from 'react-router-dom';
import { EyeOutlined, PlusSquareOutlined, RightOutlined } from '@ant-design/icons';
import { FilterValue } from 'antd/lib/table/interface';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { ProposalStatuses, PROPOSAL_STATUS_LABELS } from 'src/types/proposal';

import { LoadingButton } from 'src/components/shared-components/Loading';

import { selectIsProvider } from 'src/store/userProfile/selectors';

import s from './CompareProposal.module.css';
import './CompareProposal.css';
import pdfImg from '../../assets/img/pdf.svg';
import CartModal from './CartModal/CartModal';
import OrderNotFound from '../NotFound/OrderNotFound';
import HeaderSlider from '../../components/layout-components/Header/HeaderSlider';

import useTypedSelector from '../../hooks/useTypedSelector';
import getCookie from '../../helpers/getCookie';
import { Footer, Header } from '../../components';
import { usersApi } from '../../api/api';
import UploadInvoiceButton from './UploadInvoice';

import {
  CartDataArrayItem,
  CartDataObject,
  CompareData,
  Good,
  ICartData,
} from './types';

interface ProviderTitleProps {
  offer: CompareData;
  index: number;
  updateCompareData: () => void;
  testCartDataFromServer: CartDataObject | CartDataArrayItem[];
}

const ProviderTitle: FC<ProviderTitleProps> = (props) => {
  const {
    offer,
    index,
    updateCompareData,
    testCartDataFromServer,
  } = props;

  const {
    organization,
    status,
    isCustom,
  } = offer;

  const [isLoading, setIsLoading] = useState(false);

  const isProvider = useTypedSelector(selectIsProvider);

  const { id } = useParams<{ id: string }>();

  if (isProvider) {
    return (
      <span>
        Поставщик
        {' '}
        {index + 1}
      </span>
    );
  }

  if (!isCustom) {
    return (
      <span>
        {organization}
        <br />
        {PROPOSAL_STATUS_LABELS[status]}
      </span>
    );
  }

  const hasOrganizationGoodInCart = Array.isArray(testCartDataFromServer)
    && testCartDataFromServer
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .some(({ item }) => item?.offer.organization === organization);

  const handleClick = async () => {
    try {
      setIsLoading(true);

      await usersApi.deleteCompareProviderOffer({
        orderId: Number(id),
        offerId: offer.id,
      });

      updateCompareData();

      message.success('Счет успешно удален!');
    } catch (e) {
      console.warn(e);

      message.error('Произошла ошибка при удалении счета');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <span>{organization}</span>
      <br />
      <div>
        <span>
          Счет .pdf
        </span>
        {!hasOrganizationGoodInCart && (
          <Button
            disabled={isLoading}
            type="link"
            onClick={handleClick}
          >
            <span className={cx(isLoading && s.buttonContentHidden)}>
              удалить
            </span>
            {isLoading && (
              <span className={s.buttonLoader}>
                <LoadingButton />
              </span>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

interface CompareProposalProps {
  isMobile: boolean;
}

const CompareProposal: React.FC<CompareProposalProps> = ({ isMobile }) => {
  const [isCartModalOpened, setIsCartModalOpened] = useState<boolean>(false);
  const [compareData, setCompareData] = useState<CompareData[]>([]);
  const [compareDataFilter, setCompareDataFilter] = useState<string | null>(null);
  const [cartData] = useState<ICartData[]>([]);
  const [isMoreColumnsVisible, setIsMoreColumnsVisible] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [allProductCount, setAllProductCount] = useState<number>(0);
  const [isOrderValid, setIsOrderValid] = useState<boolean>(true);
  const [isCloseOrderBtnVisible, setIsCloseOrderBtnVisible] = useState<boolean>(false);
  const [lowestPriceArr, setLowestPriceArr] = useState<any>([]);
  const [responseFromServer, setResponseFromServer] = useState<any>(0);
  const [testCartDataFilter, setTestCartDataFilter] = useState<string | null>(null);
  const [isProposalActive, setIsProposalActive] = useState(false);

  const [
    testCartDataFromServer,
    setTestCartDataFromServer,
  ] = useState<CartDataObject | Array<CartDataArrayItem>>([]);
  const { items } = useTypedSelector((state) => state);
  const isProvider = useTypedSelector(selectIsProvider);
  const { isRefresh, isOrderClosed } = useTypedSelector((state) => state.refresh);
  const { isCartRefreshed } = useTypedSelector((state) => state.cartItems);

  const [isRequestSuccess, setIsRequestSuccess] = useState(false);

  const { id }: any = useParams();

  const filteredCompareData = useMemo(() => (
    compareData.filter(({ organization }) => (
      !compareDataFilter || organization === compareDataFilter
    ))
  ), [compareData, compareDataFilter]);

  const updateCompareData = () => {
    const token: string | null | undefined = getCookie('Authentication');

    usersApi.getCompareData(token, id).then((r) => {
      if (r.data.offers.length !== 0) {
        const testArray = r.data.offers
          .map((item: any) => ({
            ...item,
            goods: item.goods.sort((a: any, b: any) => a.offersGoods.id - b.offersGoods.id),
            status: ProposalStatuses.DONE,
          }))
          .concat(r.data.offersStatus || []);
        setCompareData(testArray);
        setIsOrderValid(true);
        setIsRequestSuccess(true);
      } else {
        setIsRequestSuccess(true);
        setIsOrderValid(true);
        setCompareData(r.data.order.goods);
      }

      setIsProposalActive(r.data.active);

      // setOffersStatus(r.data.offersStatus || null);
    }).catch(() => {
      setIsOrderValid(false);
    });
  };

  useEffect(() => {
    if (!isCartModalOpened) {
      updateCompareData();
    }
  }, [id, isRefresh, isCartModalOpened]);

  useEffect(() => {
    let canSendOffers = false;

    if (!testCartDataFilter) {
      canSendOffers = Array.isArray(testCartDataFromServer) && compareData
        .filter(({ organization }) => (
          testCartDataFromServer.find(({ item }) => (
            item?.offer.organization === organization
          ))
        ))
        .every(({ active }) => active);
    } else {
      const choosedProvider = compareData.find(({ organization }) => (
        organization === testCartDataFilter
      ));

      canSendOffers = (choosedProvider as CompareData).active;
    }

    setIsCloseOrderBtnVisible(canSendOffers);
  }, [testCartDataFilter, compareData, testCartDataFromServer]);

  const sortCartDataById = (data: Array<CartDataArrayItem>) => (
    Array.from(data)
      .sort((a, b) => {
        if (!a.id || !b.id) {
          return 0;
        }

        return a.id - b.id;
      })
  );

  const updateCartData = (data: Array<CartDataArrayItem>) => {
    setTestCartDataFromServer((previousCartData) => {
      const typedPreviousCartData = previousCartData as Array<CartDataArrayItem>;

      if (!typedPreviousCartData.length || data.length !== typedPreviousCartData.length) {
        return data;
      }

      return typedPreviousCartData.map((item) => {
        if (item.id === responseFromServer.id) {
          const editedItem = data.find((dataItem) => (
            dataItem.id === responseFromServer.id
          ));

          if (editedItem) {
            return editedItem;
          }

          const newCartDataSorted = sortCartDataById(data);

          return newCartDataSorted[data.length - 1];
        }

        return item;
      });
    });
  };

  useEffect(() => {
    const token: string | null | undefined = getCookie('Authentication');

    if (isRequestSuccess && responseFromServer?.msg !== 'Товар удален') {
      usersApi.getCartData(token, id)
        .then((r) => updateCartData(r.data));
    }
  }, [responseFromServer, isRefresh, isRequestSuccess, isCartRefreshed]);

  useEffect(() => {
    if (compareData[0]?.goods) {
      setAllProductCount(compareData[0]?.goods?.length);
    } else {
      setAllProductCount(compareData.length);
    }

    const test: any = {};
    const min: any[] = [];
    const arr: any[] = [];

    for (let i = 0; i < compareData?.length; i += 1) {
      for (let j = 0; j < compareData[i]?.goods?.length; j += 1) {
        test[compareData[i]?.goods[j].offersGoods.id] = [];
        arr[compareData[i]?.goods[j].offersGoods.id] = [];
      }
    }

    for (let i = 0; i < compareData.length; i += 1) {
      for (let j = 0; j < compareData[i]?.goods?.length; j += 1) {
        test[compareData[i]?.goods[j].offersGoods.id]
          .push(compareData[i]?.goods[j].price);
        if (compareData[i]?.goods[j].price !== '0.00') {
          arr[compareData[i]?.goods[j].offersGoods.id]
            .push(compareData[i]?.goods[j].price);
        }
      }
    }

    Object.keys(test).forEach((key) => {
      min.push([Math.min(...arr[Number(key)])]);
    });

    setLowestPriceArr(min);

    if (compareData.length > 0) {
      setTableLoading(false);
    }
  }, [compareData]);

  useEffect(() => {
    if (!Array.isArray(testCartDataFromServer) && testCartDataFromServer?.result) {
      setIsCloseOrderBtnVisible(false);
    }
  }, [testCartDataFromServer]);

  const closeOrder = () => {
    const token: string | null | undefined = getCookie('Authentication');
    const data = new FormData();
    data.append('orderId', id);
    usersApi.closeCart(token, id, data).then((r) => {
      if (r.data.result) {
        message.success('Заказ отправлен в архив!', 5);
        setIsCloseOrderBtnVisible(false);
      } else message.error('Заказ не найден!', 5);
    });
  };

  useEffect(() => {
    if (isOrderClosed) {
      closeOrder();
    }
  }, [isOrderClosed]);

  const addProductToCart = debounce((cartProduct: ICartData | Good, index: number) => {
    const token: string | null | undefined = getCookie('Authentication');
    const goods = [
      {
        productId: cartProduct.offersGoods.id,
        offerId: filteredCompareData[index].id,
        amount: cartProduct.amount ? cartProduct.amount : cartProduct.offersGoods.amount,
        fixedQuantity: Boolean(cartProduct.amount),
      },
    ];

    const typedCartData = testCartDataFromServer as CartDataObject;

    if (typedCartData.msg) {
      usersApi.addProductToCartPost(token, id, goods).then((r) => {
        setTestCartDataFromServer([...goods]);
        setResponseFromServer(r.data);
        message.success('Позиция добавлена в корзину!', 3);
      });
    }
    if (!typedCartData.msg) {
      usersApi.addProductToCartPatch(token, id, goods).then((r) => {
        setResponseFromServer(r.data);
        message.success('Позиция добавлена в корзину!', 3);
      });
    }
  }, 300);

  const getProposalFields = (item: CompareData, index: number) => {
    const hasGoods = item.goods;

    return [
      {
        title: 'Итог руб.',
        key: 'name',
        className: s.leftCellBorder,
        render: (text: string, record: CompareData, indexRender: number) => {
          if (!hasGoods) {
            return null;
          }

          const a: any = [];
          filteredCompareData[index]?.goods?.forEach((x: any) => {
            if (!a.includes(x)) {
              a.push(x);
            }
          });

          function contains(cd: any, it: any, org: string) {
            for (let i = 0; i < cd.length; i += 1) {
              if (cd[i].item.offersGoods.name === it && cd[i].item.offer.organization === org) {
                return true;
              }
            }
            return false;
          }

          if (filteredCompareData[0].goods && indexRender < item.goods.length) {
            const offersGoodsName = item.goods[indexRender]?.offersGoods.name;

            const canAddProduct = (
              !Array.isArray(testCartDataFromServer)
                || !testCartDataFromServer[0]?.item
                || !contains(testCartDataFromServer, offersGoodsName, item.organization)
            );

            return (
              <div className={`${s.plusBtn_container} ${s.plusBtn_container__centered}`}>
                <span
                  className={cx({ [s.disabled]: !canAddProduct })}
                >
                  {!isProvider && (
                    <PlusSquareOutlined
                      onClick={() => addProductToCart(item.goods[indexRender], index)}
                    />
                  )}

                </span>
                <span
                  className={lowestPriceArr[indexRender][0] === Number(item.goods[indexRender].price) ? s.bold : ''}
                >
                  {parseFloat((Number(a[indexRender]?.price) * Number(a[indexRender].amount
                    ? a[indexRender].amount : a[indexRender]?.offersGoods.amount)).toString())}
                </span>
              </div>
            );
          }
          if (filteredCompareData[0].goods && indexRender === item.goods.length) {
            let sum = 0;
            filteredCompareData[0].goods.forEach((_, goodIndex) => {
              sum += Number(a[goodIndex]?.price) * Number(a[goodIndex].amount
                ? a[goodIndex].amount : a[goodIndex]?.offersGoods.amount);
            });
            return <b>{sum.toFixed()}</b>;
          }

          return null;
        },
        align: 'center',
        width: 180,
      },
      {
        title: 'Количество',
        key: 'amount',
        render: (text: string, record: CompareData, indexRender: any) => {
          if (!hasGoods) {
            return null;
          }

          if (indexRender < item.goods.length) {
            const a: any = [];
            filteredCompareData[index].goods.map((x) => !a.includes(x) && a.push(x));
            return a[indexRender].amount
              ? a[indexRender].amount
              : a[indexRender]?.offersGoods.amount;
          }
          if (indexRender === item.goods.length) {
            let sum = 0;
            item.goods.forEach((goodItem) => {
              sum += Number(
                goodItem.amount
                  ? goodItem.amount
                  : goodItem.offersGoods.amount,
              );
            });
            return <b>{sum.toFixed()}</b>;
          }
          return (
            item.uploadedFile
              ? (
                <a href={item.uploadedFile}>
                  <img
                    className={s.pdfIcon}
                    width={30}
                    src={pdfImg}
                    alt="download file"
                  />
                </a>
              ) : '—'
          );
        },
        align: 'center',
        width: 180,
      },
      {
        title: 'Комментарий',
        key: 'name',
        className: s.rightCellBorder,
        render: (_: string, record: CompareData, indexRender: number) => {
          if (!hasGoods) {
            return null;
          }

          if (filteredCompareData[0].goods && indexRender < item.goods.length) {
            if (item.goods[indexRender].comment === null) {
              return '-';
            }

            return (
              <Tooltip
                className="eye_tooltip"
                color="white"
                trigger="click"
                placement="left"
                title={(
                  <div className={s.tooltip}>
                    <div className={s.tooltip_header}>
                      Комментарий к
                      :
                      {record.name}
                    </div>
                    <div
                      className={s.tooltip_value}
                    >
                      {item.goods[indexRender].comment}
                    </div>
                  </div>
                )}
              >
                <EyeOutlined className={s.eye} />
              </Tooltip>
            );
          }

          return null;
        },
        align: 'center',
        width: 180,
      },
    ];
  };

  const fullColumns = [
    {
      title: 'Наименование товара',
      dataIndex: 'name',
      key: 'name',
      className: s.bottomCellBorder,
      width: 300,
      fixed: 'left' as 'left',
      filters: compareData.map(({ organization }) => ({
        text: organization,
        value: organization,
      })),
      render: (text: string, record: CompareData, indexRender: any) => {
        if (filteredCompareData[0].goods && indexRender < filteredCompareData[0].goods.length) {
          return record.name;
        }
        if (filteredCompareData[0].goods === undefined) {
          return record.name;
        }
        if (filteredCompareData[0].goods && indexRender === filteredCompareData[0].goods.length) {
          return (
            <b>Итого</b>
          );
        }

        return null;
      },
      filterMultiple: false,
    },
    {
      title: 'Кол-во',
      key: 'quantity1',
      dataIndex: 'amount',
      className: s.bottomCellBorder,
      width: 50,
      render: (text: string, record: CompareData, indexRender: number) => {
        let sum = 0;
        filteredCompareData[0]?.goods?.forEach((item) => {
          sum += item.offersGoods.amount;
        });
        if (filteredCompareData[0].goods && indexRender < filteredCompareData[0].goods.length) {
          return record.amount;
        }
        if (!filteredCompareData[0].goods && indexRender < filteredCompareData.length) {
          return record.amount;
        }
        if (filteredCompareData[0].goods && indexRender === filteredCompareData[0].goods.length) {
          return <b>{sum}</b>;
        }
        return null;
      },
    },
    {
      title: 'Единица измерения',
      key: 'type',
      render: (text: string, record: CompareData) => record.type?.name,
      width: 100,
    },
    ...filteredCompareData.map((item, index) => ({
      title: (
        <ProviderTitle
          offer={item}
          index={index}
          updateCompareData={updateCompareData}
          testCartDataFromServer={testCartDataFromServer}
        />
      ),
      className: cx(s.rightCellBorder, s.leftCellBorder),
      key: 'organization',
      children: getProposalFields(item, index),
      width: 300,
    })),
  ];

  const collapsedColumns = [
    {
      title: 'Наименование товара',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      fixed: 'left' as 'left',
      className: s.leftCellBorder,
      render: (text: any, record: any, indexRender: any) => {
        if (filteredCompareData[0].goods && indexRender < filteredCompareData[0].goods.length) {
          if (filteredCompareData[0].goods) {
            return record.name;
          }
        }
        if (filteredCompareData[0].goods === undefined) {
          return record.name;
        }
        if (filteredCompareData[0].goods && indexRender === filteredCompareData[0].goods.length) {
          return (
            <b>Итого</b>
          );
        }

        return null;
      },
    },
    {
      title: 'Кол-во',
      key: 'quantity1',
      dataIndex: 'amount',
      className: s.leftCellBorder,
      render: (text: any, record: any, indexRender: any) => {
        let sum = 0;
        filteredCompareData[0]?.goods?.forEach((item) => {
          sum += item.offersGoods.amount;
        });
        if (filteredCompareData[0].goods && indexRender < filteredCompareData[0].goods.length) {
          return record.amount;
        }
        if (!filteredCompareData[0].goods && indexRender < filteredCompareData.length) {
          return record.amount;
        }
        if (filteredCompareData[0].goods && indexRender === filteredCompareData[0].goods.length) {
          return <b>{sum}</b>;
        }

        return null;
      },
    },
    {
      title: 'Лучшие предложения',
      children:
        [{
          title: 'Цена',
          key: 'name',
          render: (text: any, record: any, indexRender: any) => (record.name !== 'Итого' ? (
            <div>
              {filteredCompareData.map((el: any, index: number) => {
                const a: any = [];
                filteredCompareData[index]?.goods?.map((x) => !a.includes(x) && a.push(x));

                function contains(cd: any, it: any, org: string) {
                  for (let i = 0; i < cd.length; i += 1) {
                    if (cd[i].item.offersGoods.name === it
                      && cd[i].item.offer.organization === org) {
                      return true;
                    }
                  }
                  return false;
                }

                if (el.goods
                  && indexRender < el.goods?.length
                  && lowestPriceArr?.length > 0
                  && (
                    lowestPriceArr[indexRender][0]
                    === Number(el!.goods[indexRender]!.price)
                  )
                ) {
                  const offersGoodsName = el.goods[indexRender]?.offersGoods.name;

                  const canAddProduct = (
                    !Array.isArray(testCartDataFromServer)
                    || !testCartDataFromServer[0]?.item
                    || !contains(testCartDataFromServer, offersGoodsName, el.organization)
                  );

                  return (
                    <div className={`${s.plusBtn_container}`}>
                      <span
                        className={cx({ [s.disabled]: !canAddProduct })}
                      >
                        {!isProvider && (
                          <PlusSquareOutlined
                            onClick={() => addProductToCart(el.goods[indexRender], index)}
                          />
                        )}
                      </span>
                      <span
                        className={lowestPriceArr[indexRender][0] === Number(el.goods[indexRender].price) ? s.bold : ''}
                      >
                        {(Number(a[indexRender]?.price) * Number(a[indexRender]?.amount
                          ? a[indexRender]?.amount
                          : a[indexRender]?.offersGoods.amount)).toFixed(2)}
                      </span>
                    </div>
                  );
                }
                if (el.goods && indexRender === el.goods.length) {
                  let sum = 0;
                  filteredCompareData[0].goods.forEach((_, newIndex) => {
                    sum += Number(a[newIndex]?.price)
                      * Number(a[newIndex]?.offersGoods.amount);
                  });
                  return <b>{sum.toFixed()}</b>;
                }

                return null;
              })}
            </div>
          ) : (
            <div>
              <span
                className={s.bold}
              >
                {record.name === 'Итого' ? lowestPriceArr.reduce((acc: number, a: Array<number>, index: number) => {
                  let finalPrice = 0;
                  filteredCompareData[index]?.goods?.map((el, indexItem) => {
                    if (+el.price === lowestPriceArr[indexItem][0]) {
                      finalPrice += (lowestPriceArr[indexItem][0] * (el.amount
                        ? el.amount : el.offersGoods.amount));
                    }

                    return null;
                  });

                  return acc + finalPrice;
                }, 0) : ''}
              </span>
            </div>
          )),
        },
        {
          title: 'Фикс. Кол-во',
          key: 'name',
          render: (text: any, record: any, indexRender: any) => (record.name !== 'Итого' ? (
            <div>
              {filteredCompareData.map((el: any) => (
                el.goods
                && indexRender < el.goods.length
                && lowestPriceArr.length > 0
                && lowestPriceArr[indexRender][0] === Number(el!.goods[indexRender]!.price)
                && el.goods[indexRender].amount !== el.goods[indexRender].offersGoods.amount
                && (
                  <p className={s.best_price_p}>
                    {' '}
                    {el.goods[indexRender].amount}
                    {' '}
                  </p>
                )))}
            </div>
          ) : <div />),
        },
        {
          title: 'Поставщик',
          key: 'name',
          render: (text: any, record: any, indexRender: any) => (record.name !== 'Итого' ? (
            <div>
              {filteredCompareData.map((el: any) => (
                el.goods
                  && indexRender < el.goods.length
                  && lowestPriceArr.length > 0
                  && lowestPriceArr[indexRender][0] === Number(el!.goods[indexRender]!.price)
                  ? <p className={s.best_price_p}>{isProvider ? '' : el.organization}</p>
                  : ''
              ))}
            </div>
          ) : <div />),
        },
        {
          title: 'Комментарий',
          key: 'name',
          render: (text: any, record: any, indexRender: any) => (record.name !== 'Итого' ? (
            <div className={s.comment}>
              {filteredCompareData.map((el: any) => {
                const flag = el.goods
                  && indexRender < el.goods.length
                  && lowestPriceArr.length > 0
                  && lowestPriceArr[indexRender][0] === Number(el!.goods[indexRender]!.price);

                if (flag) {
                  if (el.goods[indexRender].comment === null) {
                    return (
                      <p className={s.best_price_p}>—</p>
                    );
                  }
                  return (
                    <Tooltip
                      className="eye_tooltip"
                      color="white"
                      trigger="click"
                      placement="left"
                      title={(
                        <div className={s.tooltip}>
                          <div className={s.tooltip_header}>
                            Комментарий к
                            :
                            {record.name}
                          </div>
                          <div
                            className={s.tooltip_value}
                          >
                            {el.goods[indexRender].comment}
                          </div>
                        </div>
                      )}
                    >
                      <EyeOutlined className={s.eye} />
                    </Tooltip>
                  );
                }
                return '';
              })}
            </div>
          ) : <div />),
        },
        ],

    },
  ];

  const data: any = [];

  if (filteredCompareData.length !== 0 && filteredCompareData[0].goods) {
    for (let i = 0; i < filteredCompareData[0].goods.length; i += 1) {
      data.push({
        key: i,
        code: filteredCompareData[0].goods[i].offersGoods.code,
        amount: filteredCompareData[0].goods[i].offersGoods.amount,
        type: filteredCompareData[0].goods[i].offersGoods.type,
        name: filteredCompareData[0].goods[i].offersGoods.name,
        price: filteredCompareData[0].goods[i].price,
        comment: filteredCompareData[0].goods[i].comment,
      });
    }

    data.push({
      code: '',
      amount: '',
      type: '',
      name: 'Итого',
      price: '',
      comment: '',
    });
  } else {
    for (let i = 0; i < filteredCompareData.length; i += 1) {
      data.push({
        key: i,
        code: filteredCompareData[i].code,
        amount: filteredCompareData[i].amount,
        type: '',
        name: filteredCompareData[i].name,
        price: '',
        comment: '',
      });
    }
  }

  const handleCompareTableChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    setCompareDataFilter((filters.name && filters.name[0]) as string);
  };

  const handleCompareTableToggle = () => {
    setIsMoreColumnsVisible(!isMoreColumnsVisible);
    setCompareDataFilter(null);
  };

  if (!isOrderValid) {
    return (
      <OrderNotFound />
    );
  }

  return (
    <>
      {!isMobile ? <Header /> : <HeaderSlider />}
      <div className={s.page}>
        {items && cartData && isCartModalOpened && (
          <CartModal
            isCloseOrderBtnVisible={isCloseOrderBtnVisible}
            setResponseFromServer={setResponseFromServer}
            testCartDataFromServer={testCartDataFromServer}
            isModal={isCartModalOpened}
            setIsModal={setIsCartModalOpened}
            compareData={compareData}
            updateCompareData={updateCompareData}
            testCartDataFilter={testCartDataFilter}
            setTestCartDataFilter={setTestCartDataFilter}
          />
        )}
        <div className={s.subtitle}>Сравнение предложений</div>
        <div className={isMoreColumnsVisible ? s.table_title : s.table_title_collapsed}>
          <div>Предложения</div>
        </div>
        <div className="compare-table-container">
          {!isProvider && (
            <div className={s.cart}>
              <div className={s.cartButtons}>
                <Button
                  onClick={() => setIsCartModalOpened(true)}
                  type="primary"
                >
                  Перейти в корзину
                </Button>
                {isProposalActive && (
                  <UploadInvoiceButton updateCompareData={updateCompareData} />
                )}
              </div>
              {!isOrderClosed && (
                <div>
                  <Popconfirm
                    placement="topLeft"
                    title="После закрытия заявки вы не сможете отправить выбранные товары поставщикам! Закрыть заявку?"
                    onConfirm={closeOrder}
                    okText="Да"
                    cancelText="Нет"
                    overlayInnerStyle={{ maxWidth: '350px' }}
                  >
                    <Button type="primary" danger>Закрыть заявку</Button>
                  </Popconfirm>
                </div>
              )}

            </div>
          )}
          <div className={s.tableWithLeftArrowContainer}>
            <div
              onClick={handleCompareTableToggle}
              className={s.left_scroll_btn}
            >
              <span
                className={s.revert_scroll_btn_span}
              >
                {allProductCount > 3 ? 'Ваша заявка' : null}

              </span>
              <RightOutlined
                className={isMoreColumnsVisible ? `${s.left_scroll_btn_arrow} ${s.rotated_arrow}` : s.left_scroll_btn_arrow}
              />
            </div>
            <Table
              loading={tableLoading}
              dataSource={data}
              pagination={false}
              columns={isMoreColumnsVisible ? fullColumns : collapsedColumns}
              size="small"
              bordered
              onChange={handleCompareTableChange}
            />
          </div>
          <div className={s.contactTableContainer}>
            <table className={s.contactTable}>
              <tr>
                <td>Доставка</td>
                {filteredCompareData[0]?.goods && filteredCompareData.map((item, index) => (
                  <td>
                    {!isProvider ? item.organization : `Поставщик ${index + 1}`}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Способ доставки</td>
                {filteredCompareData.map((item) => (
                  <td>
                    {item.deliveryType?.name || '-'}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Дата доставки</td>
                {filteredCompareData.map((item) => (
                  <td>
                    {item.deliveryDate || '-'}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Стоимость доставки</td>
                {filteredCompareData.map((item) => (
                  <td>
                    {item.deliveryPrice || '-'}
                  </td>
                ))}
              </tr>
              <tr>
                <td>Стоимость товара с доставкой</td>
                {filteredCompareData.map((item) => {
                  if (!item.goods) {
                    return (
                      <td>
                        <b>-</b>
                      </td>
                    );
                  }

                  const goodsCost = item.goods.reduce((memo, x) => (
                    memo + (Number(x.price) * x.offersGoods.amount)
                  ), 0);

                  return (
                    <td>
                      <b>
                        {(goodsCost + Number(item.deliveryPrice)).toFixed()}
                      </b>
                    </td>
                  );
                })}
              </tr>
            </table>
          </div>
          {!isProvider && (
            <div className={s.contactTableContainer}>
              <table className={s.contactTable}>
                <tr>
                  <th className="table-label">Контактные данные</th>
                  {filteredCompareData[0]?.goods && filteredCompareData.map((_, index: number) => (
                    <td>
                      {`Предложение ${index + 1}`}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Организация</td>
                  {filteredCompareData.map((item: CompareData) => (
                    <td>
                      {item.organization}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Имя</td>
                  {filteredCompareData.map((item) => (
                    <td>
                      {item.goods ? item.name : ''}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Телефон</td>
                  {filteredCompareData.map((item) => (
                    <td>
                      {item.phone}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Электронная почта</td>
                  {filteredCompareData.map((item) => (
                    <td>
                      <a href={`mailto:${item?.offerUser?.email}`}>{item?.offerUser?.email}</a>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Сайт</td>
                  {filteredCompareData.map((item: any) => {
                    const url = item?.offerUser?.website || '';
                    return (
                      <td>
                        {url?.includes('https://' || 'http://')
                          ? (
                            <a
                              target="_blank"
                              href={url}
                              rel="noreferrer"
                            >
                              {item?.offerUser?.website}
                            </a>
                          )
                          : (
                            <a
                              target="_blank"
                              href={`https://${url}`}
                              rel="noreferrer"
                            >
                              {item?.offerUser?.website}
                            </a>
                          )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>Комментарий</td>
                  {filteredCompareData.map((item) => (
                    <td>
                      <textarea className={s.textAreaComment} readOnly>
                        {item?.deliveryComment}
                      </textarea>
                    </td>
                  ))}
                </tr>
              </table>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CompareProposal;
