/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-regex-literals */
import React from 'react';

import {
  Form,
  Input,
  Select,
} from 'antd';

import InputMask from 'react-input-mask';

import cities from '../../assets/data/cities';

import s from './styles.module.css';

const AddCustomerBranchForm = () => (
  <div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Название филиала</span>
      <Form.Item
        className={s.formItem}
        name="branchName"
        rules={[{
          required: true,
          message: 'Заполните название филиала',
          pattern: /^[a-zA-ZА-Яа-я\s\d\-_]+$/,
        }]}
      >
        <Input
          placeholder="Наименование филиала"
          maxLength={200}
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Имя</span>
      <Form.Item
        className={s.formItem}
        name="userName"
        rules={[{
          required: true,
          message: 'Пожалуйста, введите свое имя кириллицей!',
          pattern: new RegExp('^[А-Яа-я]+$'),
        }]}
      >
        <Input
          placeholder="Имя"
          maxLength={20}
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Телефон</span>
      <Form.Item
        className={s.formItem}
        name="phone"
        rules={[{
          required: true,
          message: 'Заполните телефон',
        }]}
      >
        <InputMask
          className="ant-input"
          placeholder="Телефон"
          mask="+7(999) 999-9999"
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Организация</span>
      <Form.Item
        name="organization"
        className={s.formItem}
        rules={[{
          required: true,
          message: 'Укажите название организации',
        }]}
      >
        <Input
          maxLength={200}
          placeholder="Организация"
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Сайт компании</span>
      <Form.Item
        className={s.formItem}
        rules={[{
          message: 'Пожалуйста, введите ссылку на сайт корректно!',
          pattern: new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\\=]*)?/gi),
        }]}
        name="website"
      >
        <Input
          maxLength={200}
          placeholder="Сайт компании"
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Регион доставки</span>
      <Form.Item
        name={['region', 'name']}
        className={s.formItem}
        rules={[{
          required: true,
          message: 'Выберите регион доставки',
        }]}
      >
        <Select
          placeholder="Выберите регион"
        >
          {cities.map((city) => (
            <Select.Option
              key={city}
              value={city}
            >
              {city}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>Адрес доставки</span>
      <Form.Item
        name="address"
        className={s.formItem}
        rules={[{
          required: true,
          message: 'Укажите адрес доставки',
        }, {
          max: 100,
          message: 'Адрес доставки не должен быть больше 100 символов',
        }]}
      >
        <Input.TextArea
          placeholder="Адрес доставки"
          maxLength={255}
        />
      </Form.Item>
    </div>
    <div className={s.inputGroupItem}>
      <span className={s.inputDescription}>
        Электронная почта
      </span>
      <Form.Item
        className={s.formItem}
        name="email"
        rules={[{
          required: true,
          message: 'Пожалуйста, введите свою почту корректно!',
          pattern: new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/),
        }]}
      >
        <Input
          placeholder="Электронная почта"
          maxLength={50}
        />
      </Form.Item>
    </div>
  </div>
);

export default AddCustomerBranchForm;
