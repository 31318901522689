import React, { useEffect } from 'react';
import { Button } from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ContainerOutlined from '@ant-design/icons/ContainerOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getUserData } from 'src/store/userProfile/actions';

import useTypedSelector from 'src/hooks/useTypedSelector';

import AddCredentials from 'src/components/shared-components/Modals/AddCredentials';
import LoaderCentered from 'src/components/shared-components/Loader/LoaderCentered';

import routes from 'src/routes';

import { IEmployeeCredentials } from 'src/constants/enums';
import { saveEmployeeCredentials } from 'src/store/employees/actions';
import ProviderBranchesTable from './ProviderBranchesTable';

import s from './styles.module.css';

const Employees = () => {
  const { isFetching } = useTypedSelector((state) => state.auth);

  const {
    deletingEmployeeId,
    isCredentialsSaving,
    savingError,
  } = useTypedSelector((store) => store.employees);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!deletingEmployeeId) {
      dispatch(getUserData());
    }
  }, [deletingEmployeeId]);

  const handleSubmit = (values: IEmployeeCredentials) => {
    dispatch(saveEmployeeCredentials(values));
  };

  return (
    <div className={s.branches}>
      <div className={s.links}>
        <Link to={routes.personal}>
          <Button
            type="default"
            icon={<ContainerOutlined />}
            size="large"
          >
            Мои предложения
          </Button>
        </Link>
        <Link to={routes.settings}>
          <Button
            type="default"
            icon={<SettingOutlined />}
            size="large"
          >
            Настройки
          </Button>
        </Link>
      </div>
      <div className={s.header}>
        <h1 className={s.title}>
          Сотрудники
        </h1>
        <AddCredentials
          title="Задайте логин и пароль для сотрудников"
          error={savingError}
          isSaving={isCredentialsSaving}
          onSubmit={handleSubmit}
        />
      </div>
      <NavLink
        className={s.addBranch}
        to={routes.employees.add}
      >
        Добавить сотрудника
        <span className={s.addBranchIcon}>
          <PlusOutlined />
        </span>
      </NavLink>

      {isFetching && (
        <LoaderCentered />
      )}

      {!isFetching && (
        <ProviderBranchesTable />
      )}
    </div>
  );
};

export default Employees;
