import {
  ActionsTypes,
  EmployeesActions,
  EmployeesState,
} from 'src/types/employees';

const initialState: EmployeesState = {
  deletingEmployeeId: null,
  isCredentialsSaving: false,
  savingError: null,
};

const branchesReducer = (
  state = initialState,
  action: EmployeesActions,
): EmployeesState => {
  switch (action.type) {
    case ActionsTypes.DELETE_EMPLOYEE_REQUEST: {
      return {
        ...state,
        deletingEmployeeId: action.payload.id,
      };
    }

    case ActionsTypes.DELETE_EMPLOYEE_SUCCESS:
    case ActionsTypes.DELETE_EMPLOYEE_FAIL: {
      return {
        ...state,
        deletingEmployeeId: null,
      };
    }

    case ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_REQUEST: {
      return {
        ...state,
        isCredentialsSaving: true,
      };
    }

    case ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        isCredentialsSaving: false,
      };
    }

    case ActionsTypes.SAVE_EMPLOYEE_CREDENTIALS_FAIL: {
      return {
        ...state,
        isCredentialsSaving: false,
        savingError: action.payload.error,
      };
    }

    default:
      return state;
  }
};

export default branchesReducer;
