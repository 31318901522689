import { IBranch } from 'src/constants/enums';

export enum ActionsTypes {
  DELETE_BRANCH = 'DELETE_BRANCH',
  DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST',
  DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS',
  DELETE_BRANCH_FAIL = 'DELETE_BRANCH_FAIL',

  SAVE_BRANCH_CREDENTIALS = 'SAVE_BRANCH_CREDENTIALS',
  SAVE_BRANCH_CREDENTIALS_REQUEST = 'SAVE_BRANCH_CREDENTIALS_REQUEST',
  SAVE_BRANCH_CREDENTIALS_SUCCESS = 'SAVE_BRANCH_CREDENTIALS_SUCCESS',
  SAVE_BRANCH_CREDENTIALS_FAIL = 'SAVE_BRANCH_CREDENTIALS_FAIL',
}

export interface BranchesState {
  deletingBranchId: number | null;
  isCredentialsSaving: boolean;
  savingError: string | null;
}

export interface DeleteBranch {
  type: ActionsTypes.DELETE_BRANCH,
}

export interface DeleteBranchRequest {
  type: ActionsTypes.DELETE_BRANCH_REQUEST,
  payload: Pick<IBranch, 'id'>
}

export interface DeleteBranchSuccess {
  type: ActionsTypes.DELETE_BRANCH_SUCCESS,
}

export interface DeleteBranchFail {
  type: ActionsTypes.DELETE_BRANCH_FAIL,
}

export interface SaveBranchCredentials {
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS,
}

export interface SaveBranchCredentialsRequest {
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS_REQUEST,
}

export interface SaveBranchCredentialsSuccess {
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS_SUCCESS,
}

export interface SaveBranchCredentialsFail {
  type: ActionsTypes.SAVE_BRANCH_CREDENTIALS_FAIL,
  payload: {
    error: string;
  }
}

export type BranchesActions = (
  DeleteBranch
  | DeleteBranchRequest
  | DeleteBranchSuccess
  | DeleteBranchFail
  | SaveBranchCredentials
  | SaveBranchCredentialsRequest
  | SaveBranchCredentialsSuccess
  | SaveBranchCredentialsFail
);
