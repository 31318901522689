import React from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import MarketGoodsList from 'src/components/MarketGoodsList';

import routes from 'src/routes';

import MarketStyles from 'src/pages/Market/index.module.css';

import useMarket from './useMarket';

import s from './index.module.css';

const MarketCategories = () => {
  const { categories } = useMarket();

  return (
    <>
      <h1 className={MarketStyles.pageContentTitle}>
        Витрина стоматологического агрегатора DentMP
      </h1>
      <p className={MarketStyles.pageContentDescription}>
        Ниже выводятся последние добавленные товары в соответствующие категории
      </p>
      {!categories && (
        <div className={MarketStyles.pageContentLoader}>
          <LoadingOutlined style={{ fontSize: 48 }} />
        </div>
      )}
      {categories && (
        <ul className={s.categories}>
          {categories.map(({ name, showcaseGoods }) => (
            <li className={s.category}>
              <div className={s.categoryHeader}>
                <h2 className={s.categoryTitle}>{name}</h2>
                <NavLink
                  className={s.categoryLink}
                  to={`${routes.market}?supplierType=${name}`}
                >
                  Смотреть все
                </NavLink>
              </div>
              <MarketGoodsList items={showcaseGoods} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default MarketCategories;
