import React, { useEffect, useRef } from 'react';

import {
  Input,
  Pagination,
  Table,
} from 'antd';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import _uniqBy from 'lodash/uniqBy';
import _find from 'lodash/find';
import cx from 'classnames';

import {
  IGoodType, IOffer, IOfferGood, IOrder,
} from 'src/constants/enums';

import usePurchaseTable from './usePurchaseTable';

import s from './index.module.css';

const PurchaseTable = () => {
  const {
    status,
    purchaseHistory,
    tableFilter,
    filter,
    page,
    limit,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
    onTableChange,
  } = usePurchaseTable();

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [filter]);

  const {
    history,
    filterNames = [],
  } = purchaseHistory;

  const items = history.items.filter(({ name }) => (
    !tableFilter
      || tableFilter.includes(name)
  ));

  const uniqueProviders = _uniqBy(items.reduce<IOffer[]>((memo, item) => (
    memo.concat(item.offersGoods.map(({ offer }) => offer))
  ), []), 'column');

  const columns = [
    {
      title: 'Заявка',
      key: 'order',
      dataIndex: 'order',
      showSorterTooltip: false,
      className: s.leftCellBorder,
      render: (order: IOrder) => (
        <span className={s.tableCellText}>
          №
          {' '}
          {order.id}
          {' '}
          от
          {' '}
          {order.date}
        </span>
      ),
      width: 150,
      fixed: 'left' as 'left',
    },
    {
      title: 'Название товара',
      key: 'name',
      dataIndex: 'name',
      showSorterTooltip: false,
      filters: filterNames.map((name) => ({
        text: name,
        value: name,
      })),
      filteredValue: tableFilter,
      filterMultiple: false,
      render: (text: string) => (
        <span title={text} className={s.tableCellName}>
          {text}
        </span>
      ),
      width: 170,
      fixed: 'left' as 'left',
    },
    {
      title: 'Кол-во',
      key: 'amount',
      dataIndex: 'amount',
      showSorterTooltip: false,
      render: (amount: number) => (
        <span className={s.tableCellText}>
          {amount}
        </span>
      ),
      width: 70,
      fixed: 'left' as 'left',
    },
    {
      title: 'Единица измерения',
      key: 'type',
      dataIndex: 'type',
      showSorterTooltip: false,
      render: (type: IGoodType) => (
        <span className={s.tableCellText}>
          {type.name}
        </span>
      ),
      width: 120,
      fixed: 'left' as 'left',
      className: s.tableCellColumn,
    },
    ...uniqueProviders.map(({ column, organization }, index) => ({
      title: organization || 'Из загруженного счета',
      key: `provider-${column}`,
      showSorterTooltip: false,
      className: cx(index === uniqueProviders.length - 1 && s.rightCellBorder, s.leftCellBorder),
      children: [
        {
          title: 'Итог руб.',
          dataIndex: 'offersGoods',
          className: s.leftCellBorder,
          render: (offersGoods: IOfferGood[]) => {
            const offer = _find(offersGoods, ['offer.column', column]);

            if (!offer) {
              return (
                <span className={s.tableCellText}>
                  -
                </span>
              );
            }

            return (
              <span className={cx(s.tableCellText, offer.inCart && s.tableCellTextBold)}>
                {Number(offer.price) * (offer.amount || 0)}
              </span>
            );
          },
          align: 'center',
          width: 100,
        },
        {
          title: 'Количество',
          dataIndex: 'offersGoods',
          render: (offersGoods: IOfferGood[]) => {
            const offer = _find(offersGoods, ['offer.column', column]);

            if (!offer) {
              return (
                <span className={s.tableCellText}>
                  -
                </span>
              );
            }

            return (
              <span className={s.tableCellText}>
                {offer.amount || 0}
              </span>
            );
          },
          align: 'center',
          width: 120,
        },
        {
          title: 'Комментарий',
          dataIndex: 'offersGoods',
          className: cx(index === uniqueProviders.length - 1 && s.rightCellBorder),
          render: (offersGoods: IOfferGood[]) => {
            const offer = _find(offersGoods, ['offer.column', column]);

            if (!offer) {
              return null;
            }

            return null;
          },
          align: 'center',
          width: 140,
        },
      ],
    })),
  ];

  return (
    <>
      <div className={s.searchInputContainer}>
        <Input
          ref={inputRef}
          key={filter} // сделано для синхронизации выбранного фильтра в таблице и урла
          defaultValue={filter}
          className={s.searchInput}
          placeholder="Наименование, артикул или модерация"
          prefix={<SearchOutlined />}
          onChange={onFilterChange}
        />
      </div>
      {status === 'loading' && (
        <div className={s.pageContentLoader}>
          <LoadingOutlined style={{ fontSize: 48 }} />
        </div>
      )}
      {status !== 'loading' && (
        <>
          {!items.length && (
            <div className={s.empty}>
              <p className={s.emptyText}>
                Нет истории заказов
              </p>
            </div>
          )}
          {items.length > 0 && (
            <>
              <div className={s.tableContainer}>
                <Table
                  className={s.table}
                  dataSource={items}
                  columns={columns}
                  pagination={false}
                  tableLayout="fixed"
                  onChange={onTableChange}
                  scroll={{ x: 510 }}
                />
              </div>
              <Pagination
                current={page}
                pageSize={limit}
                showSizeChanger
                pageSizeOptions={['10', '20', '50']}
                onChange={onPageChange}
                onShowSizeChange={onPageSizeChange}
                total={history.meta.totalItems}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PurchaseTable;
