import React from 'react';
import { Button, ConfigProvider } from 'antd';
import { NavLink } from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';

import {
  ContainerOutlined,
  PlusOutlined,
  TableOutlined,
} from '@ant-design/icons';

import routes from 'src/routes';

import GoodsTable from './GoodsTable';

import s from './index.module.css';

const PersonalMarket = () => (
  <ConfigProvider locale={ru_RU}>
    <div className={s.page}>
      <div className={s.pageBody}>
        <div className={s.links}>
          <NavLink
            className={s.link}
            to={routes.personal}
          >
            <Button
              type="default"
              icon={<ContainerOutlined />}
              size="large"
            >
              Мои предложения
            </Button>
          </NavLink>
          <NavLink
            className={s.link}
            to={routes.personalMarket.index}
          >
            <Button
              type="default"
              icon={<TableOutlined />}
              size="large"
            >
              Моя витрина
            </Button>
          </NavLink>
        </div>
        <div className={s.pageHeader}>
          <h1 className={s.pageTitle}>Витрина</h1>
          <NavLink className={s.goToMarketLink} to={routes.market}>
            Посмотреть витрину
            <div className={s.goToMarketLinkIcon}>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.29031 2.74359L3.79532 3.67914L4.75026 4.63401L1.31831 8.06597C1.13897 8.2453 1.13897 8.53618 1.31831 8.71552C1.49765 8.89486 1.78852 8.89486 1.96786 8.71552L5.39981 5.28356L6.35476 6.23851L7.29031 2.74359Z" fill="#5B55B0" />
                <path d="M8.61852 0H3.27676C2.51574 0 1.89528 0.620462 1.89528 1.38148V1.89528H1.38148C0.620462 1.89528 0 2.51574 0 3.27676V8.61852C0 9.37954 0.620462 10 1.38148 10H6.72324C7.48426 10 8.10472 9.37954 8.10472 8.61852V8.10472H8.61852C9.37954 8.10472 10 7.48426 10 6.72324V1.38148C10 0.620462 9.37954 0 8.61852 0V0ZM9.27289 6.72319C9.27289 7.08187 8.9772 7.37756 8.61852 7.37756H3.88265L3.15552 8.10949L7.37747 8.10468V8.61848C7.37747 8.97715 7.08178 9.27285 6.7231 9.27285H1.38133C1.02266 9.27285 0.726962 8.97715 0.726962 8.61848V3.27671C0.726962 2.91803 1.02266 2.62234 1.38133 2.62234H1.89514L1.88544 6.94125L2.62226 6.21902V1.38136C2.62226 1.02268 2.91795 0.726988 3.27663 0.726988H8.6184C8.97708 0.726988 9.27277 1.02268 9.27277 1.38136L9.27289 6.72319Z" fill="#5B55B0" />
              </svg>
            </div>
          </NavLink>
        </div>

        <NavLink
          className={s.addGoodLink}
          to={{
            pathname: routes.personalMarket.add,
            state: { from: window.location.href },
          }}
        >
          Добавить товар по одному
          <span className={s.addGoodLinkIcon}>
            <PlusOutlined />
          </span>
        </NavLink>

        <GoodsTable />
      </div>
    </div>
  </ConfigProvider>
);

export default PersonalMarket;
