import React from 'react';
import { UserTypes } from 'src/constants/enums';
import useTypedSelector from 'src/hooks/useTypedSelector';

import s from './styles.module.css';

const TermsOfUse = () => {
  const { isAuth } = useTypedSelector((state) => state.auth);
  const { type } = useTypedSelector((state) => state.userProfile);

  const isProvider = type && [UserTypes.Provider, UserTypes.Employee].includes(type);
  const isCustomer = type && [UserTypes.Customer, UserTypes.Branch].includes(type);

  return (
    <div className={s.page}>
      <div className={s.title}>Правила работы на сайте</div>

      {(!isAuth || isProvider) && (
        <div className={s.block}>
          <h2 className={s.subtitle}>Для поставщиков</h2>
          <ul className={s.list}>
            <li className={s.listItem}>
              Не использовать получаемую информацию (данные о заказчике, состав заказа)
              как инсайдерскую информацию, т. е. не делать предложение заказчику в обход площадки.
            </li>
            <li className={s.listItem}>
              Не использовать контактные данные заказчика для получения информации
              о предложениях других поставщиков.
            </li>
            <li className={s.listItem}>
              При проставлении цены учитывать ед. измерения запрошенные заказчиком (ед/уп)
            </li>
            <li className={s.listItem}>
              При указании цены на аналог, указывать в комментарии наименование
              и характеристики предлагаемого аналога.
            </li>
          </ul>
        </div>
      )}

      {(!isAuth || isCustomer) && (
        <div className={s.block}>
          <h2 className={s.subtitle}>Для заказчиков</h2>
          <ul className={s.list}>
            <li className={s.listItem}>
              Не разглашать цены поставщиков, сделавших предложения в программе,
              до закрытия заявки.
            </li>
            <li className={s.listItem}>
              Не забывать закрывать заявку после отправки поставщикам выбранных позиций.
            </li>
            <li className={s.listItem}>
              После закрытия заявки не разглашать наименования поставщиков сделавших предложения.
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default TermsOfUse;
